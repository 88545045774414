<template>
  <div class="modal-copy-paste">
    <div class="modal-copy-paste__dialog-wr">
      <div class="modal-copy-paste__header">
        <SvgIconPaste class="modal-copy-paste__main-icon" />
        <h4 class="modal-copy-paste__title">
          {{ $t('nmhEditor.modals.copyPaste.title') }}
          <code v-if="isMacOs">CMD+V</code>
          <code v-else>CTRL+V</code>
        </h4>
        <SvgIconTimes class="modal-copy-paste__times" @click="modalHandlerToggle" />
      </div>
      <div class="modal-copy-paste__content-wr" :class="{ 'modal-copy-paste__content-wr--loading' : laoding }">
        <EditorContent class="modal-copy-paste__code-area" :editor="editor" />
        <button class="modal-copy-paste__btn-send" @click="clickHandler">
          {{ $t('nmhEditor.modals.copyPaste.btnSend') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { mapState } from 'vuex'
import SvgIconTimes from '@/assets/img/svg/times.svg?inline'
import SvgIconPaste from '@/assets/img/svg/paste.svg?inline'
import CoreApi from '@/api/core'
import StarterKit from '@tiptap/starter-kit'
import ArticleService from '@/services/article/ArticleService'
import { allTextExtensions } from '@/services/nmhEditor/NmhEditorService'

export default {
  name: 'ModalCopyPaste',
  components: {
    SvgIconTimes,
    SvgIconPaste,
    EditorContent
  },
  data () {
    return {
      editor: null,
      laoding: false
    }
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      selectBlockModalReplaceMode: state => state.nmhEditorStore.modals.selectBlock.replaceMode,
      currentArticle: state => state.article.detail
    }),
    isMacOs () {
      return window.navigator.appVersion.indexOf('Mac') !== -1
    }
  },
  methods: {
    clickHandler () {
      this.laoding = true
      const article = this._.cloneDeep(this.currentArticle)
      article.field.bodyText = this.editor.getHTML()
      CoreApi().post('/ArticleBodyConverter/articleFieldToBlocksConversion', JSON.stringify(ArticleService.prepareApiRequest(article)))
        .then(response => {
          this.$store.commit('nmhEditorStore/SET_BLOCKS', {
            init: {
              blocks: response.data
            }
          })
          this.laoding = false
          this.modalHandlerToggle()
        })
        .catch(error => {
          this.laoding = false
          this.modalHandlerToggle()
          console.error(error)
        })
    },
    modalHandlerToggle () {
      this.$store.commit('nmhEditorStore/SET_MODAL_COPY_PASTE', false)
    }
  },
  mounted () {
    document.body.style.overflowY = 'hidden'
    this.editor = new Editor({
      content: '',
      dropCursor: { width: 0, color: 'transparent' },
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      extensions: [
        ...allTextExtensions,
        StarterKit.configure({
          // Nodes
          blockquote: true,
          bulletList: true,
          codeBlock: true,
          document: true,
          hardBreak: true,
          heading: {
            levels: [1, 2, 3]
          },
          horizontalRule: false,
          listItem: true,
          orderedList: true,
          paragraph: true,
          text: true,
          // Marks
          bold: true,
          code: true,
          italic: true,
          strike: true,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        })
      ]
    })
    setTimeout(() => {
      this.$el.querySelector('.ProseMirror').focus()
    }, 100)
  },
  destroyed () {
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style lang="scss" scoped>
.modal-copy-paste {
  @include fixed(0, 100);
  @include size(100% 100vh);
  @include padding(_ 10px);
  overflow-y: scroll;
  background: rgba(0,0,0,.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &__dialog-wr {
    @include size(760px _);
    @include margin(40px _);
    @include radius(6px);
    overflow: hidden;
    background: #f2f3f7;
  }
  &__header {
    @include size(_ 60px);
    @include padding(_ 30px);
    @include grid-gap(8px);
    background: #fff;
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto min-content;
  }
  &__times {
    cursor: pointer;
    transform: scale(1);
    transition: all 200ms;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__title {
    @include font(500 18px "Roboto");
    @include margin(0);
    color: #465674;
    code {
      @include font(400 16px "Fira Code");
      @include padding(3px 8px);
      @include radius(4px);
      @include margin(_ _ _ 10px);
      background: #465674;
      color: #fff;
    }
  }
  &__main-icon {
    @include size(20px auto);
    fill: #6599fe;
  }
  &__content-wr {
    @include padding(30px);
    &--loading {
      opacity: .5;
      pointer-events: none;
    }
  }
  &__btn-send {
    @include font(500 16px "Roboto");
    @include padding(7px 20px);
    @include radius(4px);
    @include margin(10px _ _ auto);
    display: grid;
    color: #fff;
    background: #6599fe;
    border: none;
    cursor: pointer;
  }
  &__code-area {
    @include size(100% 300px);
    @include radius(6px);
    @include padding(10px);
    overflow-y: scroll;
    display: block;
    border: 1px solid #d5d5d5;
    background: #fff;
  }
  /deep/.ProseMirror {
    @include size(100%);
  }
}
</style>
