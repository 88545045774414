<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ArticleActivitiesChecker',
  data () {
    return {
      trackingId: 0
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      trackingInterval: state => state.activity.trackingInterval
    })
  },
  methods: {
    getArticleActivitiesInLoop () {
      this.trackingId = setInterval(() => {
        this.$store.dispatch('activity/getArticleActivities')
      }, this.trackingInterval)
    }
  },
  created () {
    this.$store.dispatch('activity/getArticleActivities')
    this.getArticleActivitiesInLoop()
  },
  beforeDestroy () {
    clearInterval(this.trackingId)
  }
}
</script>
