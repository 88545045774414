<template>
  <div class="modal-source-block">
    <div class="modal-source-block__dialog-wr">
      <div class="modal-source-block__header">
        <div>
          {{ $t('nmhEditor.modals.source.title') }}
        </div>
        <div class="d-flex justify-content-between">
          <button class="modal-source-block__btn" @click="save">
            {{ $t('buttons.save') }}
          </button>
          <button class="modal-source-block__btn modal-source-block__btn--close" @click="close">
            {{ $t('buttons.close') }}
          </button>
        </div>
      </div>
      <div class="modal-source-block__items-wrapper">
        <div
          v-for="(source, index) in items"
          :key="`source-${index}`"
          class="modal-source-block__items"
        >
          <div class="modal-source-block__items__item">
            <div v-if="index === 0" class="modal-source-block__items__item__title">
              {{ `${$t('nmhEditor.modals.source.name')} *` }}
            </div>
            <Input
              v-model="source.name"
              @blur="$v.items.$each[index].name.$touch()"
              :error="$v.items.$each[index].name.$error"
              :id="`source_name-${index}`"
              no-label
              disable-form-group
            />
          </div>
          <div class="modal-source-block__items__item">
            <div v-if="index === 0" class="modal-source-block__items__item__title">
              {{ $t('nmhEditor.modals.source.url') }}
            </div>
            <Input
              v-model="source.url"
              @blur="$v.items.$each[index].url.$touch()"
              :error="$v.items.$each[index].url.$error"
              :id="`source_url-${index}`"
              no-label
              disable-form-group
            />
          </div>
          <div class="modal-source-block__items__delete-button">
            <ModuleFormButton
              v-if="items.length > 1"
              icon="delete"
              @click.stop="removeSource(index)"
            />
          </div>
        </div>
        <div>
          <button class="modal-source-block__btn modal-source-block__btn--add" @click="addSource">
            <PlusIcon class="modal-source-block__btn__icon" />
            {{ $t('nmhEditor.modals.source.addSource') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import NotifyService from '@/services/NotifyService'
import { isURL } from '@/filters'

export default {
  name: 'ModalSource',
  data () {
    return {
      items: []
    }
  },
  components: {
    Input,
    ModuleFormButton,
    PlusIcon
  },
  validations: {
    items: {
      $each: {
        name: {
          required,
          maxLengthPerWord: function (value) {
            return !value || !value.split(/\s+/).some(word => word.length > 40)
          }
        },
        url: {
          isUrl: value => !value || isURL(value)
        }
      }
    }
  },
  computed: {
    ...mapState({
      blocks: state => state.nmhEditorStore.data.blocks,
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock
    })
  },
  methods: {
    addSource () {
      this.items.push({ name: '', url: '' })
    },
    removeSource (index) {
      this.items.splice(index, 1)
    },
    save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.lastClickedBlock.index,
        property: 'items',
        data: this.items
      })
      this.close()
    },
    close () {
      this.$store.commit('nmhEditorStore/SET_MODAL_SOURCE', false)
      const blockData = this.blocks[this.lastClickedBlock.index]
      if (blockData.properties.items.length === 0) {
        this.$store.commit('nmhEditorStore/SET_BLOCKS', {
          deleteSingleBlock: true
        })
      }
    }
  },
  created () {
    const blockData = this.blocks[this.lastClickedBlock.index]
    this.items = blockData.properties.items ? this._.cloneDeep(blockData.properties.items) : []
    if (this.items.length === 0) {
      this.addSource()
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-source-block {
    @include fixed(0, 100);
    @include size(100% 100vh);
    @include padding(_ 10px);
    overflow-y: scroll;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &__dialog-wr {
      @include size(750px _);
      @include radius(6px);
      background: #f2f3f7;
      box-shadow: 0 0 30px 5px #8d8d8d;
    }
    &__header {
      @include font(500 18px "Roboto");
      padding: 1rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
    }
    &__btn {
      @include font(700 13px "Roboto");
      @include padding(7px 14px);
      @include radius(4px);
      margin-right: 0.5rem;
      height: rem(30px);
      cursor: pointer;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      display: flex;
      justify-content: center;
      align-items: center;
      @include bp(10) {
        &:hover {
          background: darken(#6599fe, 10%);
        }
      }
      &__icon {
        @include size(rem(14px));
        fill: #FFFFFF;
        float: left;
        margin-right: rem(8px);
      }
      &--close {
        background: #545b62;
        &:hover {
          background: darken(#545b62, 10%);
        }
      }
      &--add {
        margin-top: 1rem;
      }
    }
    &__items-wrapper {
      margin: 1rem;
    }
    &__items {
      display: flex;
      gap: 1rem;
      padding: 0.2rem;
      &__item {
        width: 100%;
        &__title {
          color: #8A96AC;
          font-size: rem(14px);
        }
      }
      &:first-child {
        .modal-source-block__items__delete-button {
          margin-top: rem(27px);
        }
      }
      &__delete-button {
        margin: auto;
      }
    }
  }
</style>
