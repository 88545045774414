<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :show-edit-btn="true"
    :click-handler-for-edit-btn="showSourceModal"
    :format-toolbar="[]"
  >
    <div>
      <span class="source-title">
        {{ `${$t('nmhEditor.blocks.sourceBlock.title')}:`}}
      </span>
      <span v-for="(source, index) in properties.items" :key="`source-${index}`" class="source">
        <span>
          <a
            v-if="source.url"
            :href="source.url"
            target="_blank"
            class="source__link"
          >
            <span>{{ source.name }}</span>
          </a>
          <span v-else>{{ source.name }}</span>
        </span>
      </span>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
export default {
  name: 'SourceBlock',
  components: {
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  methods: {
    showSourceModal () {
      this.$store.commit('nmhEditorStore/SET_MODAL_SOURCE', true)
    }
  }
}
</script>

<style scoped lang="scss">
.source-title {
  font-weight: 500;
}
.source {
  &__link {
    color: inherit;
    text-decoration: underline;
  }
  &:not(:last-child)::after {
    content: ', ';
  }
}
</style>
