<template>
  <div class="editor-tooltip">
    <div class="editor-tooltip__text">
      <slot></slot>
    </div>
    <div class="editor-tooltip__arrow"></div>
  </div>
</template>

<script>
export default {
  name: 'EditorTooltip'
}
</script>

<style scoped lang="scss">
  .editor-tooltip {
    @include absolute(_ _ -39px _, 3);
    @include radius(4px);
    @include font(500 13px 20px "Roboto");
    @include padding(4px 10px);
    color: #fff;
    background: #000;
    pointer-events: none;
    @include bp(0 11) {
      display: none !important;
    }
    &__text {
      @include relative(_, 2);
      background: #000;
      white-space: nowrap;
    }
    &__arrow {
      @include absolute(-6px calc(50% - 7px) _ 1);
      @include size(12px);
      transform: rotate(45deg);
      background: #000;
    }
  }
</style>
