<template>
    <span>
        <div v-if="articles.length > 0">
            <router-link
              v-if="selectedArticle.setting.status === 'draft'"
              tag="a"
              :to="{name: 'article_detail', params: {id: articles[0].id}}"
              target="_blank"
              class="label label-warning p-1 article-version-info"
            >
                <i class="fas fa-info-circle"></i> {{ $t('article.version_info_published') }}
            </router-link>
            <router-link
              v-if="selectedArticle.setting.status === 'published'"
              tag="a"
              :to="{name: 'article_edit', params: { id: articles[0].id }}"
              target="_blank"
              class="label label-warning p-1 article-version-info"
            >
                <i class="fas fa-info-circle"></i> {{ $t('article.version_info_draft') }}
            </router-link>
        </div>
        <div v-if="selectedArticle.originalDocumentId">
          <div
            @click="openOriginalArticle(selectedArticle.originalDocumentId)"
            class="label label-warning p-1 article-version-info"
          >
            <i class="fas fa-info-circle"></i> {{ $t('article.transfer_to_export.open_original') }}
          </div>
        </div>
    </span>
</template>

<script>
import _ from 'lodash'
import CoreApi from '@/api/core'
import ArticleFilterModel from '@/model/ArticleFilter'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'ArticleVersionsInfo',
  props: {
    selectedArticle: {
      type: Object
    }
  },
  data () {
    return {
      articles: []
    }
  },
  methods: {
    fetchArticlesByDocumentId () {
      CoreApi().get('/article?filter_neq[id]=' + this.selectedArticle.id + '&filter_in[documentId]=' + this.selectedArticle.documentId)
        .then(response => {
          if (response.data.totalCount > 0) {
            this.articles = response.data.data
          }
        })
        .catch(error => console.error(error))
    },
    async openOriginalArticle (documentId) {
      try {
        const foundArticles = await this.$store.dispatch('article/fetchByDocumentId', {
          documentId,
          withoutElastic: true
        })
        const totalCount = foundArticles.length
        if (totalCount > 0) {
          if (totalCount === 1) {
            this.$router.push(`/article/${foundArticles[0].id}`)
          } else {
            const filter = _.cloneDeep(ArticleFilterModel)
            filter.id = documentId
            this.$store.commit('article/setFilter', filter)
            this.$store.commit('article/setPage', 1)
            this.$router.push('/article')
          }
        } else {
          NotifyService.setErrorMessage('Not found')
        }
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error.response?.data?.title ?? error)
      }
    },
    close () {
      this.$emit('close')
    }
  },
  created () {
    this.fetchArticlesByDocumentId()
  }
}
</script>

<style lang="scss">
.article-version-info {
  cursor: pointer;
}

.article-version-info:hover {
  background-color: #ec971f;
  color: #fff;
}
</style>
