<template>
  <router-link
    v-if="showButton"
    :to="{ name: 'native_campaign_from_article', params: { id: article.id } }"
    :title="$t('route.native_campaign_from_article')"
    tag="a"
  >
    <span class="btn-create-campaign__content">
      <SponsoredArticlesIcon class="btn-create-campaign__icon" />
    </span>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import SponsoredArticlesIcon from '@/assets/img/svg/sponsored-articles.svg?inline'

export default {
  name: 'BtnCreateCampaign',
  props: {
    article: {
      type: Object
    }
  },
  components: {
    SponsoredArticlesIcon
  },
  computed: {
    ...mapGetters(['vlm']),
    showButton () {
      return !this.vlm && (this.article.flag?.native || this.article.setting?.prArticle)
    }
  }
}
</script>

<style scoped lang="scss">
.btn-create-campaign {
  &__content {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(4px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px solid #6599FE;
      .btn-create-campaign__icon {
        fill: #6599FE;
      }
    }
  }
  &__icon {
    width: rem(14px);
    height: auto;
    fill: #9DAAC4;
  }
}
</style>
