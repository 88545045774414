<template>
  <div class="btn-up-and-down">
    <div class="btn-up-and-down__icon" @click="clickHandler('down')">
      <SvgIconArrowDown class="btn-up-and-down__svg" />
    </div>
    <div class="btn-up-and-down__line"></div>
    <div class="btn-up-and-down__icon" @click="clickHandler('up')">
      <SvgIconArrowUp class="btn-up-and-down__svg" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgIconArrowDown from '@/assets/img/svg/arrow-down.svg?inline'
import SvgIconArrowUp from '@/assets/img/svg/arrow-up.svg?inline'
export default {
  name: 'BtnUpAndDown',
  components: {
    SvgIconArrowDown,
    SvgIconArrowUp
  },
  computed: {
    ...mapState({
      blocks: state => state.nmhEditorStore.data.blocks,
      draggableDisabledBlocks: state => state.nmhEditorStore.draggableDisabledBlocks
    })
  },
  methods: {
    clickHandler (position) {
      const block = this.$parent.$parent.$parent.$el
      const originalIndex = parseInt(block.dataset.index)
      let newIndex
      if (position === 'down') {
        newIndex = originalIndex + 1
      }
      if (position === 'up') {
        newIndex = originalIndex - 1
      }
      if (newIndex < 0 || newIndex + 1 > this.blocks.length) {
        return
      }
      if (this.draggableDisabledBlocks.includes(this.blocks[newIndex].type)) {
        return
      }
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        changeSingleBlockPosition: {
          position: originalIndex,
          newIndex: newIndex,
          mobile: true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-up-and-down {
    @include radius(4px);
    border: 1px solid #d1dbe4;
    background: #fff;
    display: grid;
    grid-template-columns: max-content min-content max-content;
    &__icon {
      @include size(22px 20px);
      @include font(500 17px "Roboto");
      padding: 0;
      color: #9daac4;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;
    }
    &__svg {
      @include size(16px);
      fill: #9daac4;
    }
    &__line {
      @include size(2px calc(100% - 4px));
      @include margin(2px _ _);
      background: #d1dbe4;
    }
  }
</style>
