<template>
  <Modal
    :title="$t(`themeSpecialAndSeries.${type === 'special' ? type + 's' : type}.select_modal_header`)"
    @close="close"
  >
    <!-- Header -->
    <template slot="header"></template>

    <!-- Body -->
    <template slot="body">
      <ThemeSpecialAndSeriesViewList
        :dataModel="type"
        selectable
        @selectedRecord="setData"
        @saveData="saveData"
      />
      <div class="select-wrapper col-6">
        <SelectInput
          v-if="type !== 'series'"
          v-model="record.display"
          class="display-select"
          id="theme_special_and_series_display_select"
          optionTitle="title"
          :options="display"
          :noEmptyValue="true"
          :required="false"
          :label="$t('themeSpecialAndSeries.select_display_type')"
        />
        <SelectInput
          v-model="record.articlesCount"
          class="articlesCount-select"
          id="theme_special_and_series_articlesCount_select"
          optionTitle="id"
          :options="articlesCount"
          :noEmptyValue="true"
          :required="false"
          :label="$t('themeSpecialAndSeries.select_articlesCount')"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ThemeSpecialAndSeriesViewList from '@/views/themeSpecialAndSeries/ThemeSpecialAndSeriesViewList.vue'
import { mapGetters } from 'vuex'
import SelectInput from '@/components/form/select/Select.vue'

export default {
  name: 'themeSpecialAndSeriesModal',
  components: {
    SelectInput,
    Modal,
    ThemeSpecialAndSeriesViewList
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fullData: {
        series: null,
        theme: null,
        special: null
      },
      record: {
        seriesId: null,
        specialArticleGroupId: null,
        themeId: null,
        articlesCount: 5,
        display: 'cards'
      },
      articlesCount: [
        { id: 3 },
        { id: 5 },
        { id: 10 }
      ],
      display: [
        {
          id: 'links',
          title: this.$t('themeSpecialAndSeries.select_display_type_links')
        },
        {
          id: 'cards',
          title: this.$t('themeSpecialAndSeries.select_display_type_cards')
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapGetters('article', ['fullArticle'])
  },
  methods: {
    close () {
      this.$emit('close')
    },
    setData (data) {
      this.fullData[data.currentTab] = data.record[data.currentTab]
      switch (data.currentTab) {
        case 'special':
          this.fullData = {
            series: null,
            theme: null,
            special: data.record[data.currentTab]
          }
          this.record = {
            ...this.record,
            seriesId: null,
            specialArticleGroupId: data.record[data.currentTab]?.id || null,
            themeId: null
          }
          break
        case 'theme':
          this.fullData = {
            series: null,
            theme: data.record[data.currentTab],
            special: null
          }
          this.record = {
            ...this.record,
            seriesId: null,
            specialArticleGroupId: null,
            themeId: data.record[data.currentTab]?.id || null
          }
          break
        case 'series':
          this.fullData = {
            series: data.record[data.currentTab],
            theme: null,
            special: null
          }
          this.record = {
            ...this.record,
            seriesId: data.record[data.currentTab]?.id || null,
            specialArticleGroupId: null,
            themeId: null,
            display: 'links'
          }
          break
        default:
          console.error(`Unknown type: ${data.currentTab}`)
      }
    },
    saveData () {
      this.$emit('saveData', this.record)
      this.close()
    }
  },
  mounted () {
    this.$store.commit('tabNavigator/SET_CURRENT_TAB', { id: null, title: null })
    if (this.type === 'series') {
      this.record.display = 'links'
    }
  }
}
</script>

<style lang="scss">

.data-list-wrapper {
  margin: 0.5rem;
  padding: 0.25rem;
}

.article-related-modal {
  &__list-simple {
    .btn-article-create {
      display: none;
    }
  }
}

.select-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;

  &>div {
    width: 50%;

    &.display-select {
      padding-right: 0.5rem;
    }

    &.articlesCount-select {
      padding-left: 0.5rem;
    }
  }
}
</style>
