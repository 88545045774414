<template>
  <div
    class="options-toolbar"
    :class="[
      (showEditBtn ? 'options-toolbar--rearrange' : ''),
      (showToolbar ? 'options-toolbar--show' : '')
    ]"
  >
    <div class="options-toolbar__option options-toolbar__option--remove">
      <BtnRemoveBlock />
    </div>
    <div v-if="editBtnIsVisible" class="options-toolbar__option edit" v-show="showEditBtn">
      <BtnEditBlock :click-handler="clickHandlerForEditBtn"/>
    </div>
    <div v-if="moveBtnIsVisible" class="options-toolbar__option options-toolbar__option--draggable">
      <BtnDraggableBlock />
    </div>
    <div
      class="options-toolbar__option options-toolbar__option--change-block"
      :class="{ 'disabled' : changeBtnIsDisabled }"
    >
      <BtnChangeBlock />
    </div>
    <div v-if="moveBtnIsVisible" class="options-toolbar__option options-toolbar__option--up-and-down">
      <BtnUpAndDown />
    </div>
  </div>
</template>

<script>
import BtnChangeBlock from '@/components/nmhEditor/btns/BtnChangeBlock'
import BtnDraggableBlock from '@/components/nmhEditor/btns/BtnDraggableBlock'
import BtnEditBlock from '@/components/nmhEditor/btns/BtnEditBlock'
import BtnUpAndDown from '@/components/nmhEditor/btns/BtnUpAndDown'
import BtnRemoveBlock from '@/components/nmhEditor/btns/BtnRemoveBlock'
export default {
  name: 'OptionsToolbar',
  components: {
    BtnChangeBlock,
    BtnDraggableBlock,
    BtnEditBlock,
    BtnUpAndDown,
    BtnRemoveBlock
  },
  props: {
    /**
     * Type of block
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Click event function
     */
    clickHandlerForEditBtn: {
      type: Function,
      required: false
    },
    /**
     * Edit button of the block
     */
    showEditBtn: {
      type: [Boolean, Number],
      required: false
    },
    /**
     * Toolbar of the block
     */
    showToolbar: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    editBtnIsVisible () {
      return this.clickHandlerForEditBtn !== undefined
    },
    moveBtnIsVisible () {
      return !this.$store.state.nmhEditorStore.draggableDisabledBlocks.includes(this.blockType)
    },
    changeBtnIsDisabled () {
      return !this.$store.state.nmhEditorStore.replaceableBlocks.includes(this.blockType)
    }
  }
}
</script>

<style scoped lang="scss">
  .options-toolbar {
    @include grid-gap(10px);
    display: grid;
    @include bp(11) {
      opacity: 0;
      visibility: hidden;
    }
    &--show {
      opacity: 1;
      visibility: visible;
    }
    @include bp(0 11) {
      display: flex;
      order: -1;
      justify-content: flex-end;
    }
    @include bp(11) {
      display: grid;
      grid-template-columns: repeat(2, min-content);
      align-content: flex-start;
    }
    &__option {
      &--draggable {
        @include bp(0 11) {
          display: none;
        }
      }
      &--up-and-down {
        order: 4;
        @include bp(11) {
          display: none;
        }
      }
      &--change-block {
        order: 3;
        &.disabled {
          opacity: .5;
          pointer-events: none;
        }
      }
    }
    &__option--remove {
      .options-toolbar--rearrange & {
        order: 3;
      }
    }
  }
</style>
