<template>
  <Modal
    :title="$t('article.modal.poll_header')"
    @close="close"
  >
    <template slot="body">
      <div class="row">
        <div class="col-lg-12">
          <PollFilter />
          <div class="card">
            <div class="card-body">
              <Preloader v-if="callingAPI" />
              <DataTable
                :data="polls"
                :totalCount="totalCount"
                :page="page"
                :config="dataTableConfig"
                selectable
                disableMultipleSelect
                @page-change="setPageAndGetRecords"
                @select-record="selectPoll"
                actionsInBlankWindow
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="sub-header">
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="insert" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import Preloader from '@/components/preloader/Preloader'
import PollFilter from '@/components/poll/PollFilterVlm'
import DataTable from '@/components/shared/DataTable'
import Modal from '@/components/shared/Modal'

export default {
  name: 'PollListModalVlm',
  data () {
    return {
      dataLoaded: false,
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('pollVlm.list.id'),
          site: this.$t('pollVlm.list.server'),
          title: this.$t('pollVlm.list.title'),
          startOfVoting: this.$t('pollVlm.list.startOfVoting'),
          endOfVoting: this.$t('pollVlm.list.endOfVoting'),
          loggedInUsersOnly: this.$t('pollVlm.list.loggedInUsersOnly'),
          createdAt: this.$t('system.created_at'),
          modifiedAt: this.$t('system.modified_at'),
          createdBy: this.$t('system.created_by')
        },
        actions: {
          detail: 'poll_detail',
          edit: 'poll_edit'
        },
        render: {
          loggedInUsersOnly: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          site: siteId => {
            const site = this.$store.getters['site/siteById'](siteId)
            if (site) {
              return site.name
            }
            return siteId
          }
        }
      },
      selectedPoll: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    polls () {
      return this.$store.getters['pollVlm/list']
    },
    totalCount () {
      return this.$store.getters['pollVlm/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['pollVlm/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    Modal,
    Preloader,
    PollFilter,
    DataTable
  },
  methods: {
    getPolls () {
      this.$store.dispatch('pollVlm/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('pollVlm/setPage', page)
      this.getPolls()
    },
    close () {
      this.$store.commit('pollVlm/resetFilter')
      this.$emit('close')
    },
    insert () {
      this.$emit('set-data', this.selectedPoll)
      this.$emit('close')
    },
    selectPoll (polls) {
      this.selectedPoll = polls[0]
    }
  },
  mounted () {
    this.page = this.$store.getters['pollVlm/page']
    this.getPolls()
  }
}
</script>
