<template>
  <button
    class="btn-draggable-block"
    @mouseover="hover"
    @mouseout="unhover"
  >
    <svg
      class="btn-draggable-block__icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      role="img"
      aria-hidden="true"
      focusable="false">
      <path d="M5 4h2V2H5v2zm6-2v2h2V2h-2zm-6 8h2V8H5v2zm6 0h2V8h-2v2zm-6 6h2v-2H5v2zm6 0h2v-2h-2v2z"></path>
    </svg>
    <EditorTooltip v-show="tooltip">
      {{ $t('nmhEditor.tooltips.moveBlock') }}
    </EditorTooltip>
  </button>
</template>

<script>
import EditorTooltip from '@/components/nmhEditor/EditorTooltip'
export default {
  name: 'BtnDraggableBlock',
  components: {
    EditorTooltip
  },
  data () {
    return {
      tooltip: false,
      hovering: false
    }
  },
  methods: {
    hover () {
      this.hovering = true
      setTimeout(() => {
        this.tooltip = this.hovering
      }, 500)
    },
    unhover () {
      this.hovering = false
      this.tooltip = false
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-draggable-block {
    @include padding(0);
    @include radius(4px);
    @include size(22px);
    @include padding(2px _);
    @include relative(_);
    cursor: move;
    border: 1px solid #d1dbe4;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border 200ms;
    &:hover {
      border: 1px solid darken(#d1dbe4, 10%);
      .btn-draggable-block__icon {
        fill: #000;
      }
    }
    &__icon {
      fill: #9daac4;
      height: 100%;
    }
  }
</style>
