<template>
  <button
    class="btn-change-block"
    @mouseover="hover"
    @mouseout="unhover"
    @click.prevent="clickHandler"
  >
    <svg class="btn-change-block__icon" viewBox="0 0 16 16" role="img">
      <path d="M4.5 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.3.4 5.9.4 3.1 3.1L.9.9.2 7.3l6.4-.7-2.1-2.1zM15.8 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2s3.6-.7 4.9-2l2.2 2.2.8-6.4z" />
    </svg>
    <EditorTooltip v-show="tooltip">
      {{ $t('nmhEditor.tooltips.changeBlock') }}
    </EditorTooltip>
  </button>
</template>

<script>
import EditorTooltip from '@/components/nmhEditor/EditorTooltip'
export default {
  name: 'BtnChangeBlock',
  components: {
    EditorTooltip
  },
  data () {
    return {
      tooltip: false,
      hovering: false
    }
  },
  methods: {
    clickHandler () {
      this.$store.commit('nmhEditorStore/SET_MODAL_SELECT_BLOCK', true)
      this.$store.commit('nmhEditorStore/SET_MODAL_SELECT_BLOCK_REPLACE_MODE', true)
    },
    hover () {
      this.hovering = true
      setTimeout(() => {
        this.tooltip = this.hovering
      }, 500)
    },
    unhover () {
      this.hovering = false
      this.tooltip = false
    }
  }
}
</script>

<style scoped lang="scss">
.btn-change-block {
  @include padding(0);
  @include radius(4px);
  @include size(22px);
  @include padding(2px _);
  @include relative(_);
  cursor: pointer;
  border: 1px solid #d1dbe4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 200ms;
  z-index: 1;
  &:hover {
    border: 1px solid darken(#d1dbe4, 10%);
    .btn-change-block__icon {
      fill: #6599fe;
    }
  }
  &__icon {
    fill: #9daac4;
    height: 100%;
  }
}
</style>
