<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="articleHasId"
    :click-handler-for-edit-btn="showModal"
  >
    <div class="link-to-gallery-block">
      <label class="link-to-gallery-block__area link-to-gallery-block__area--page">
        <span class="link-to-gallery-block__area__desc">
          {{ $t('nmhEditor.blocks.linkToGalleryBlock.page') }}
        </span>
        <input
          class="link-to-gallery-block__area__input"
          type="number"
          min="1"
          v-model.trim="linkToGalleryPage"
          :disabled="disabled"
        >
      </label>
      <label class="link-to-gallery-block__area link-to-gallery-block__area--text">
        <span class="link-to-gallery-block__area__desc">
          {{ $t('nmhEditor.blocks.linkToGalleryBlock.text') }}
        </span>
        <span v-if="showWarning" class="link-to-gallery-block__area__desc--warning">{{ $t('nmhEditor.blocks.linkToGalleryBlock.textError', { min: textLengthMin, max: textLengthMax }) }}</span>
        <input
          class="link-to-gallery-block__area__input"
          type="text"
          v-model.trim="linkToGalleryText"
          :disabled="disabled"
        >
      </label>
      <div
        v-if="articleHasId"
        class="gallery-article-block__text"
        >
          {{ properties.relatedArticleTitle }}
          <button
            class="btn btn-xs btn-danger"
            @click="removeRelatedArticle()"
          >
            <span class="fa fa-times"></span>
          </button>
        </div>
      <button v-if="!articleHasId" class="gallery-article-block__btn-empty" @click="showModal">
        {{ $t('nmhEditor.blocks.linkToGalleryBlock.relatedArticleGalleryButton') }}
      </button>
      <RelatedArticlesModal
        v-if="theModalIsVisible"
        :body-blocks="nmhEditorStoreData.blocks"
        :selectedData="selectedData"
        :multiple="false"
        :hideAiButton="true"
        :customTitle="$t('nmhEditor.blocks.linkToGalleryBlock.relatedArticleGalleryButton')"
        @close="hideModal"
        @set-data="setRelatedArticle"
      />
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import { mapGetters, mapState } from 'vuex'
import RelatedArticlesModal from '@/components/article/ArticleRelatedModal.vue'
import coreApi from '@/api/core'

export default {
  name: 'LinkToGalleryBlock',
  components: {
    RelatedArticlesModal,
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      theModalIsVisible: false,
      selectedData: [],
      articleId: null,
      relatedArticleId: null,
      articleDataTitle: null,
      textLengthMin: 8,
      textLengthMax: 150
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    articleHasId () {
      return this.properties.relatedArticleId
    },
    linkToGalleryPage: {
      set (output) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'page',
          data: parseInt(output)
        })
      },
      get () {
        return this.properties.page
      }
    },
    linkToGalleryText: {
      set (output) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'text',
          data: output
        })
      },
      get () {
        return this.properties.text
      }
    },
    showWarning () {
      return !this.vlm && (this.linkToGalleryText.length < this.textLengthMin || this.linkToGalleryText.length > this.textLengthMax)
    }
  },
  methods: {
    showModal () {
      this.theModalIsVisible = true
    },
    hideModal () {
      this.theModalIsVisible = false
    },
    async loadArticleData (id) {
      if (id.length) {
        const url = '/article?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=' + id
        try {
          const response = await coreApi().get(url)
          this.articleDataTitle = response.data.data[0].field.title
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setRelatedArticle (data) {
      this.articleId = data[0].id
      if (data[0].id !== null) {
        this.relatedArticleId = data[0].documentId.toString()
        await this.loadArticleData(this.relatedArticleId)
      } else {
        this.articleDataTitle = null
      }
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'relatedArticleId',
        data: data[0].id ?? null
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'relatedArticleDocumentId',
        data: data[0].documentId ?? null
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'relatedArticleTitle',
        data: this.articleDataTitle ?? null
      })
    },
    removeRelatedArticle () {
      const defaultData = [
        { id: null, documentId: null }
      ]
      this.setRelatedArticle(defaultData)
    }
  }
}
</script>

<style scoped lang="scss">
  .link-to-gallery-block {
    @include grid-gap(20px);
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    &__area {
      display: flex;
      flex-wrap: wrap;
      gap: rem(6px) rem(16px);
      &__desc {
        @include font(400 14px "Roboto");
        color: #8a96ac;

        &--warning {
          font-size: 11px;
          color: var(--warning);
          flex: 1;
          text-align: right;
        }
      }
      &__input {
        @include font(400 14px "Roboto");
        @include radius(6px);
        @include size(100% 40px);
        @include padding(_ 15px);
        display: flex;
        align-items: center;
        border: solid 1px #d1dbe4;
        background: #fdfdfd;
        color: #465674;
        flex: 0 0 auto;
      }
    }
  }

  .gallery-article-block {
    &__btn-empty {
      @include font(500 13px "Roboto");
      @include margin(_ auto);
      @include padding(8px 14px);
      @include radius(6px);
      cursor: pointer;
      display: block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__text {
      @include font(400 15px "Roboto");
      @include relative(_);
      @include padding(_ _ _ 15px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::before {
        @include absolute(5px _ _ 0);
        @include size(8px);
        @include radius(50%);
        background: #465674;
        content: '';
      }
    }
  }
</style>
