<template>
  <div
    v-if="articleStatusIsDraft"
    class="article-activity-info"
    v-html="getActivityByDocId(article)"
  />
</template>

<script>
import { mapState } from 'vuex'
import UserService from '@/services/user/UserService'

export default {
  name: 'ArticleActivityInfo',
  props: {
    article: {
      type: Object
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState([
      'user'
    ]),
    articleStatusIsDraft () {
      return this.article.setting.status === 'draft'
    }
  },
  methods: {
    getActivityByDocId (article) {
      const activity = this.$store.getters['activity/articleActivityById'](article.documentId)

      if (!activity) {
        return ''
      }

      return `
        <div class="alert alert-warning">
          <i class="fa fa-exclamation-circle"></i>
          <strong>${this.$store.getters['user/userById'](activity.userId)?.username ?? activity.userId}</strong>
          ${this.$t('article.activity_is_editing')}
        </div>
      `
    },
    getUserInfo (activity) {
      let userInfo = ''
      if (this.article.documentId === activity.articleUuid) {
        userInfo = UserService.getUserInfo(activity.user)
      }
      return userInfo
    }
  },
  created () {
    this.$store.dispatch('activity/getArticleActivities')
  }
}
</script>
