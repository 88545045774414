<template>
  <small>{{ $t('article.last_change_info_edited') }}:
    <strong v-if="article.draftModifiedBy">
      {{ userNameById(article.draftModifiedBy) }}
    </strong>
    <strong v-if="!article.draftModifiedBy && article.modifiedBy">
      {{ userNameById(article.modifiedBy) }}
    </strong>,
    <strong v-if="article.draftModifiedAt">
      {{ article.draftModifiedAt | prettyDateTime }}
    </strong>
    <strong v-else>
      {{ article.modifiedAt | prettyDateTime }}
    </strong>
  </small>
</template>
<script>
import UserService from '../../services/user/UserService'

export default {
  name: 'ArticleLastChangeInfo',
  props: {
    article: {
      type: Object
    }
  },
  methods: {
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    }
  }
}
</script>

<style lang="scss">

</style>
