<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="pollHasId"
    :click-handler-for-edit-btn="showPollListModal"
  >
    <button v-if="!pollHasId" class="poll-block__btn-empty" @click="showPollListModal">
      {{ $t('nmhEditor.blocks.pollBlock.empty') }}
    </button>
    <div class="poll-block__not-empty-wrapper">
      <SvgPoll v-if="pollHasId" class="poll-block__icon-poll" />
      <span v-if="pollHasId" class="poll-block__text">
        {{ $t('nmhEditor.blocks.pollBlock.notEmpty') }} - {{ pollData }}
      </span>
    </div>
    <PollCreateModal
      v-if="pollCreateModal"
      :selectedData="selectedData"
      @close="closePollCreateModal"
      @set-data="setPoll"
      @show-poll-create-modal="showPollCreateModal"
      @show-poll-list-modal="showPollListModal"
    />
    <PollListModal
      v-if="pollListModal"
      :selectedData="selectedData"
      @close="closePollListModal"
      @set-data="setPoll"
      @show-poll-create-modal="showPollCreateModal"
      @show-poll-list-modal="showPollListModal"
    />
    <PollListModalVlm
      v-if="pollListModalVlm"
      :selectedData="selectedData"
      @close="closePollListModal"
      @set-data="setPoll"
      @show-poll-list-modal="showPollListModal"
    />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import coreApi from '@/api/core'
import PollCreateModal from '@/components/article/richtext/PollCreateModal'
import PollListModal from '@/components/article/richtext/PollListModal'
import PollListModalVlm from '@/components/article/richtext/PollListModalVlm'
import SvgPoll from '@/assets/img/svg/poll.svg?inline'
import { mapGetters } from 'vuex'
export default {
  name: 'PollBlock',
  components: {
    Block,
    PollCreateModal,
    PollListModal,
    PollListModalVlm,
    SvgPoll
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedData: null,
      pollId: null,
      pollData: null,
      pollCreateModal: false,
      pollListModal: false,
      pollListModalVlm: false
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    pollHasId () {
      return this.properties.pollId !== ''
    }
  },
  methods: {
    closePollCreateModal () {
      this.pollCreateModal = false
    },
    closePollListModal () {
      if (this.vlm) {
        this.pollListModalVlm = false
      } else {
        this.pollListModal = false
      }
    },
    loadPollData (id) {
      if (!isNaN(this.properties.pollId)) {
        const url = '/poll/' + id
        return coreApi().get(url)
          .then(response => {
            this.pollData = response.data.title
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    setPoll (data) {
      this.pollId = data.id
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'pollId',
        data: this.pollId
      })
      this.loadPollData(this.pollId)
    },
    showPollCreateModal () {
      this.pollCreateModal = true
      this.pollListModal = false
    },
    showPollListModal () {
      if (this.vlm) {
        this.pollListModalVlm = true
      } else {
        this.pollListModal = true
        this.pollCreateModal = false
      }
    }
  },
  mounted () {
    if (this.pollHasId) {
      this.loadPollData(this.properties.pollId)
    }
  }
}
</script>

<style scoped lang="scss">
  .poll-block {
    &__not-empty-wrapper {
      @include grid-gap(9px);
      display: grid;
      grid-template-columns: min-content auto;
    }
    &__btn-empty {
      @include font(500 13px "Roboto");
      @include margin(_ auto);
      @include padding(8px 14px 8px);
      @include radius(6px);
      cursor: pointer;
      display: block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__icon-poll {
      @include size(16px);
    }
    &__text {
      @include font(400 18px "Roboto");
      @include margin(0);
      @include relative(-1px _ _);
      color: #465674;
    }
  }
</style>
