<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :editor="editor"
  >
    <EditorContent class="code-editor" :editor="editor" spellcheck="false" />
  </Block>
</template>

<script>
import { Editor, EditorContent, Node } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Block from '@/components/nmhEditor/blocks/Block'
import { mapState } from 'vuex'

const CodeCustom = Node.create({
  name: 'codeCustom',
  topNode: true,
  content: 'block'
})

export default {
  name: 'CodeBlock',
  components: {
    Block,
    EditorContent
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      firstContent: {
        type: 'doc',
        content: [
          {
            type: 'codeBlock',
            content: [
              {
                type: 'text',
                text: this.properties.code
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      articleEditDisabled: state => state.article.articleEditDisabled
    })
  },
  watch: {
    articleEditDisabled (disabled) {
      this.editor && this.editor.setEditable(!disabled)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.properties.code === '' ? '' : this.firstContent,
      dropCursor: { width: 0, color: 'transparent' },
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      onUpdate: ({ editor }) => {
        let output
        const isOutputEmpty = editor.getHTML().replace(/(<([^>]+)>)/gi, '')
        isOutputEmpty === '' ? output = '' : output = editor.getJSON().content[0].content[0].text
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'code',
          data: output
        })
      },
      extensions: [
        CodeCustom,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          bulletList: false,
          codeBlock: true,
          document: true,
          hardBreak: true,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          paragraph: false,
          text: true,
          // Marks
          bold: false,
          code: false,
          italic: false,
          strike: false,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        Placeholder.configure({
          placeholder: this.$t('nmhEditor.blocks.codeBlock.placeholder')
        })
      ]
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style scoped lang="scss">
  .code-editor {
    @include radius(6px);
    @include padding(10px);
    background: #414141;
    max-height: 130px;
    overflow-y: scroll;
  }
</style>
