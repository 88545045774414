<template>
  <div class="modal-copy-content">
    <div v-click-outside="() => $emit('close-modal')" class="modal-copy-content__dialog-wr">
      <div class="modal-copy-content__header">
        <SvgIconPaste class="modal-copy-content__main-icon" />
        <h4 class="modal-copy-content__title">
          {{ $t('article.generateHtmlFromPrArticle.modalTitle') }}
        </h4>
        <SvgIconTimes class="modal-copy-content__times" @click="$emit('close-modal')" />
      </div>
      <div class="modal-copy-content__content-wr" :class="{ 'modal-copy-content__content-wr--loading' : loading }">
        <textarea class="modal-copy-content__code-area" ref="codeArea" :value="contentData" readonly></textarea>
        <button class="modal-copy-content__btn-copy" @click="copy">
          {{ $t('article.generateHtmlFromPrArticle.btnCopy') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgIconTimes from '@/assets/img/svg/times.svg?inline'
import SvgIconPaste from '@/assets/img/svg/paste.svg?inline'
export default {
  name: 'ModalCopyContent',
  components: {
    SvgIconTimes,
    SvgIconPaste
  },
  props: {
    contentData: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      selectBlockModalReplaceMode: state => state.nmhEditorStore.modals.selectBlock.replaceMode,
      currentArticle: state => state.article.detail
    })
  },
  methods: {
    copy () {
      this.$refs.codeArea.select()
      document.execCommand('copy')
    }
  },
  mounted () {
    document.body.style.overflowY = 'hidden'
  },
  destroyed () {
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style lang="scss" scoped>
.modal-copy-content {
  @include fixed(0, 100);
  @include size(100% 100vh);
  @include padding(_ 10px);
  overflow-y: scroll;
  background: rgba(0,0,0,.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &__dialog-wr {
    @include size(760px _);
    @include margin(40px _);
    @include radius(6px);
    overflow: hidden;
    background: #f2f3f7;
  }
  &__header {
    @include size(_ 60px);
    @include padding(_ 30px);
    @include grid-gap(8px);
    background: #fff;
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto min-content;
  }
  &__times {
    cursor: pointer;
    transform: scale(1);
    transition: all 200ms;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__title {
    @include font(500 18px "Roboto");
    @include margin(0);
    color: #465674;
    code {
      @include font(400 16px "Fira Code");
      @include padding(3px 8px);
      @include radius(4px);
      @include margin(_ _ _ 10px);
      background: #465674;
      color: #fff;
    }
  }
  &__main-icon {
    @include size(20px auto);
    fill: #6599fe;
  }
  &__content-wr {
    @include padding(30px);
    &--loading {
      opacity: .5;
      pointer-events: none;
    }
  }
  &__btn-copy {
    @include font(500 16px "Roboto");
    @include padding(7px 20px);
    @include radius(4px);
    @include margin(10px _ _ auto);
    display: grid;
    color: #fff;
    background: #6599fe;
    border: none;
    cursor: pointer;
    transition: all 200ms;
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__code-area {
    @include size(100% 300px);
    @include radius(6px);
    @include padding(10px);
    overflow-y: scroll;
    display: block;
    border: 1px solid #d5d5d5;
    background: #fff;
  }
  /deep/.ProseMirror {
    @include size(100%);
  }
}
</style>
