<template>
  <div
    class="character-viewer"
  >
    <span class="character-viewer__count">{{ characterCount }}</span>
    <span v-if="characterLimit" class="character-viewer__slash">/</span>
    <span v-if="characterLimit" class="character-viewer__limit">{{ characterLimit }}</span>
  </div>
</template>

<script>
export default {
  name: 'CharacterViewer',
  props: {
    /**
     * Current character length
     */
    characterCount: {
      type: Number,
      required: true,
      default: 0
    },
    /**
     * Max character length
     */
    characterLimit: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .character-viewer {
    @include font(400 11px "Roboto");
    @include absolute(_ 5px 1px _);
    pointer-events: none;
  }
</style>
