<template>
  <AbstractModal
    :title="$t('nmhEditor.modals.inlineQuote.title')"
    @confirm="confirm"
    @close="close"
  >
    <template #modalContent>
      <label class="abstract-modal__label" for="abstract-modal__input">
        <span class="abstract-modal__desc">{{ $t('nmhEditor.modals.inlineQuote.citeLabel') }}: </span>
        <input
          id="abstract-modal__input"
          ref="citeInput"
          class="abstract-modal__input"
          type="text"
          v-model="cite"
        />
      </label>
    </template>
  </AbstractModal>
</template>

<script>
import AbstractModal from '@/components/nmhEditor/modals/AbstractModal'

export default {
  name: 'ModalInlineQuote',
  components: { AbstractModal },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cite: ''
    }
  },
  methods: {
    close () {
      this.$store.commit('nmhEditorStore/SET_MODAL_INLINE_QUOTE', false)
    },
    confirm () {
      if (this.cite) {
        this.editor.chain().focus().extendMarkRange('inlineQuote').setMark('inlineQuote', { cite: this.cite }).run()
      } else {
        this.editor.commands.unsetMark('inlineQuote', { extendEmptyMarkRange: true })
      }
      this.close()
    }
  },
  mounted () {
    this.$refs.citeInput.focus()
    this.cite = this.editor.getAttributes('inlineQuote')?.cite
  }
}
</script>
