<template>
  <app-modal
    :title="$t('woodWing.export.header')"
    @close="close"
  >
    <template slot="body">
      <div class="row">
        <div class="col-12">
          <p v-html="$t('woodWing.export.info', {brand})"></p>
          <app-select
            v-model="issue"
            :options="issues"
            id="wood_wing_issue"
            :label="$t('woodWing.export.print')"
          ></app-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-select
            v-model="category"
            :options="categories"
            id="wood_wing_categories"
            :label="$t('woodWing.export.category')"
          ></app-select>
        </div>
      </div>
    </template>
    <template slot="sub-header">
      <div class="flex-fill d-flex justify-content-between">
        <button
          type="button"
          class="btn btn-danger col-3 col-md-2 col-lg-1"
          @click="publishIntoWoodWing"
        >
          {{ $t("article.modal.no") }}
        </button>
        <button
          type="button"
          class="btn btn-green"
          @click="publishIntoWoodWing"
          :disabled="disabled"
        >
          {{ $t("woodWing.export.save") }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import Select from '../form/select/Select'

export default {
  name: 'WoodWingExportModal',
  components: {
    appModal: Modal,
    appSelect: Select
  },
  props: {
    siteId: {
      type: Number
    }
  },
  data () {
    return {
      issue: 0,
      category: 0
    }
  },
  computed: {
    brand () {
      return this.site.shortTitle
    },
    disabled () {
      return this.issue === 0
    },
    categories () {
      return this.$store.getters['woodWing/categories'].filter((issue) => issue.site === this.siteId)
    },
    issues () {
      return this.$store.getters['woodWing/issues'].filter((issue) => issue.site === this.siteId)
    },
    site () {
      return this.$store.getters['site/siteById'](this.siteId)
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    publishIntoWoodWing () {
      this.$emit('export', {
        issue: this.issue,
        category: this.category
      })
    }
  },
  created () {
    this.$store.dispatch('woodWing/fetchCategories')
    this.$store.dispatch('woodWing/fetchIssues')
  }
}
</script>

<style lang="scss" scoped>
</style>
