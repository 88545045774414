<template>
    <span>
        <Preloader v-if="loading" />
        <button type="button"
                v-if="article.setting.status === 'published' && hasPermissionForSite(requiredPermissions.editAsNewDraftButton)"
                @click="clone"
                class="btn btn-success"
                data-test="article_btn_edit_as_new_draft"
                :disabled="loading"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.edit_as_new_draft") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'published' && hasPermissionForSite(requiredPermissions.unpublishButton)"
                @click="changeArticleStatusToDraft"
                class="btn btn-danger"
                data-test="article_btn_unpublish"
                :disabled="loading"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.unpublish") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'review' && hasPermissionForSite(requiredPermissions.publishButton)"
                @click="changeArticleStatusToReady"
                class="btn btn-green"
                data-test="article_btn_send_to_publish"
                :disabled="loading"
        >
            <i class="fas fa-long-arrow-alt-up"></i> {{ $t("buttons.send_to_publish") }}
        </button>
        <button type="button"
                v-if="(article.setting.status === 'review' || article.setting.status === 'ready') &&
                hasPermissionForSite(requiredPermissions.sendToDraftButton)"
                @click="changeArticleStatusToDraft"
                class="btn btn-success"
                data-test="article_btn_send_to_draft"
                :disabled="loading"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.send_to_draft") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'draft' && hasPermissionForSite(requiredPermissions.publishButton)"
                @click="changeArticleStatusToReadyFromDraft"
                class="btn btn-green test123"
                data-test="article_btn_force_publish"
                :disabled="loading"
        >
            <i class="fas fa-long-arrow-alt-up"></i> {{ $t("buttons.send_to_publish") }}
        </button>
        <button type="button"
                v-if="article.setting.status === 'draft' && hasPermissionForSite(requiredPermissions.sendToReviewButton)"
                @click="changeArticleStatusToReview"
                class="btn btn-success"
                data-test="article_btn_send_to_review"
                :disabled="loading"
        >
            <i class="fas fa-exchange-alt"></i> {{ $t("buttons.send_to_review") }}
        </button>
    </span>
</template>
<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'
import { ARTICLE_ERRORS, isArticleError } from '@/model/ValueObject/ErrorCodes'
import ArticleValidationService from '@/services/article/ArticleValidationService'
import PermissionService from '@/services/PermissionService'
import Preloader from '@/components/preloader/Preloader'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'ArticleTransitionsButtons',
  props: {
    updateDraft: {
      type: Function
    }
  },
  data () {
    return {
      loading: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_PERMISSIONS
    }
  },
  components: {
    Preloader
  },
  computed: {
    ...mapState({
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    ...mapGetters(['vlm']),
    article () {
      return this.$store.getters['article/detail']
    },
    articleSiteName () {
      const site = this.$store.getters['site/siteById'](this.article.site)
      if (site) {
        return site.name
      }
      return ''
    },
    aiFeaturesUsagePayload () {
      const payload = {
        userId: this.article.createdBy,
        siteId: this.article.site,
        articleId: this.article.id,
        articleDocumentId: this.article.documentId,
        aiFeatureType: 'ai_quiz'
      }
      return payload
    }
  },
  methods: {
    ...mapActions('aiStore', ['logAiFeaturesUsage']),
    hasPermissionForSite (permission) {
      return this.$store.getters['user/hasPermission'](permission, this.articleSiteName)
    },
    handleArticleError (error) {
      const errorCode = typeof error === 'string' ? error : error?.response?.data?.error
      if (errorCode === ARTICLE_ERRORS.error_article_in_draft_exists) {
        NotifyService.setErrorMessage(this.$t('article.notify.article_in_draft_exists'))
      } else if (errorCode === ARTICLE_ERRORS.error_article_in_review_exists) {
        NotifyService.setErrorMessage(this.$t('article.notify.article_in_review_exists'))
      } else if (errorCode === ARTICLE_ERRORS.error_article_in_ready_exists) {
        NotifyService.setErrorMessage(this.$t('article.notify.article_in_ready_exists'))
      } else if (errorCode === ARTICLE_ERRORS.entity_has_redirect) {
        NotifyService.setErrorMessage(this.$t('article.notify.entity_has_redirect'))
      } else {
        NotifyService.setErrorMessage(this.$store.getters['article/error'])
      }
    },
    clone () {
      this.loading = true
      this.$store.dispatch('article/createClone', this.article)
        .then(() => {
          if (this.$store.getters['article/error']) {
            this.handleArticleError(this.$store.getters['article/error'])
          } else {
            NotifyService.setSuccessMessage(this.$t('article.notify.new_draft_created_from_published'))
            this.$router.push('/article/' + this.article.id + '/edit')
          }
        })
        .catch((error) => {
          NotifyService.setErrorMessage(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeArticleStatusToDraft () {
      this.loading = true
      CoreApi().patch('article/' + this.article.id + '/status/draft')
        .then((response) => {
          if (response.status !== 200) {
            NotifyService.setErrorMessage(response.statusText)
            return
          }
          NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_draft'))
          this.$router.push('/article/' + this.article.id + '/edit')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            if (isArticleError(error)) {
              this.handleArticleError(error)
            } else {
              NotifyService.setErrorMessage(this.$t('article.notify.article_status_changed_to_draft_forbidden'))
            }
          } else {
            NotifyService.setErrorMessage(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeArticleStatusToReview () {
      if (this.vlm) {
        const validBlocks = ArticleValidationService.filterInvalidBlocks(this.nmhEditorStoreData.blocks)
        this.article.bodyBlocks = [...validBlocks]
      }
      if (!this.vlm || this.isValid()) {
        this.loading = true
        this.updateDraft()
          .then(() => {
            if (this.isValid()) {
              CoreApi().patch('article/' + this.article.id + '/status/review')
                .then((response) => {
                  if (response.status !== 200) {
                    NotifyService.setErrorMessage(response.statusText)
                    return
                  }
                  NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_review'))
                  this.$router.push('/article/' + this.article.id)
                })
                .catch((error) => {
                  this.parseErrorResponse(error.response)
                })
                .finally(() => {
                  this.loading = false
                })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    changeArticleStatusToReady () {
      this.loading = true
      CoreApi().patch('article/' + this.article.id + '/status/ready')
        .then((response) => {
          if (response.status !== 200) {
            NotifyService.setErrorMessage(response.statusText)
            this.$router.push('/article/' + this.article.id)
            return
          }
          if (this.article.type === 'quiz' && this.article.quiz && !this.vlm) {
            this.$store.dispatch('quiz/fetchOne', this.article.quiz)
              .then(() => {
                const quiz = this.$store.getters['quiz/detail']
                if (quiz.aiGenerated) {
                  this.logAiFeaturesUsage(this.aiFeaturesUsagePayload)
                    .catch(error => {
                      console.error('Logging AI Features Usage failed:', error)
                    })
                }
              })
              .catch(error => {
                console.error('Fetching quiz failed:', error)
              })
          }

          NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_ready'))
          this.$router.push('/article')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            NotifyService.setErrorMessage(this.$t('article.notify.article_status_changed_to_ready_forbidden'))
          } else {
            NotifyService.setErrorMessage(error)
          }
          this.$router.push('/article/' + this.article.id)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeArticleStatusToReadyFromDraft () {
      if (this.vlm) {
        const validBlocks = ArticleValidationService.filterInvalidBlocks(this.nmhEditorStoreData.blocks)
        this.article.bodyBlocks = [...validBlocks]
      }
      if (!this.vlm || this.isValid(true)) {
        this.loading = true
        this.updateDraft()
          .then(() => {
            if (this.isValid(true)) {
              CoreApi().patch('article/' + this.article.id + '/status/review')
                .then((response) => {
                  if (response.status !== 200) {
                    NotifyService.setErrorMessage(response.statusText)
                    return
                  }
                  NotifyService.setSuccessMessage(this.$t('article.notify.article_status_changed_to_review'))
                  this.changeArticleStatusToReady()
                })
                .catch((error) => {
                  NotifyService.setErrorMessage(error)
                })
                .finally(() => {
                  this.loading = false
                })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    isValid (toReady = false) {
      const errors = ArticleValidationService.hasErrors(this.article, toReady)
      if (errors) {
        this.$emit('show-errors', errors)
        return false
      }
      return true
    },
    parseErrorResponse (errorResponse) {
      let fields = ''
      for (const field in errorResponse.data) {
        fields = fields + field + ', '
      }

      NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'), fields)
    }
  }
}
</script>
