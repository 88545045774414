export const INTERACTION_TYPE_ENUM = {
  READING: 0,
  DISCUSSION: 1,
  SHARING: 2
}

export const POST_LENGTH_ENUM = {
  SHORT: 15,
  MEDIUM: 30,
  LONG: 60
}
