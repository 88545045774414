import { render, staticRenderFns } from "./BtnChangeBlock.vue?vue&type=template&id=8f946980&scoped=true&"
import script from "./BtnChangeBlock.vue?vue&type=script&lang=js&"
export * from "./BtnChangeBlock.vue?vue&type=script&lang=js&"
import style0 from "./BtnChangeBlock.vue?vue&type=style&index=0&id=8f946980&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f946980",
  null
  
)

export default component.exports