<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :format-toolbar="formatOptions"
    :editor="editor"
  >
    <EditorContent :editor="editor" @click.prevent  />
    <CharacterViewer
      v-if="editor"
      :character-count="editor.storage.characterCount.characters()"
      :character-limit="characterLimit"
    />
  </Block>
</template>

<script>
import { Editor, EditorContent, Node } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Block from '@/components/nmhEditor/blocks/Block'
import CharacterViewer from '@/components/nmhEditor/CharacterViewer'
import CharacterCount from '@tiptap/extension-character-count'
import { mapState } from 'vuex'
import { allTextExtensions, allTextToolbarFormatOptions } from '@/services/nmhEditor/NmhEditorService'

const ParagraphCustom = Node.create({
  name: 'paragraphCustom',
  topNode: true,
  content: 'block+'
})

export default {
  name: 'ParagraphBlock',
  components: {
    Block,
    CharacterViewer,
    EditorContent
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      outputText: '',
      characterLimit: 5000,
      formatOptions: ['bold', 'code', 'italic', 'strike', 'underline', 'htmlIdAttr', ...allTextToolbarFormatOptions]
    }
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      articleEditDisabled: state => state.article.articleEditDisabled
    }),
    paragraphBlock () {
      const blocksModel = JSON.parse(JSON.stringify(this.blocksModel))
      return blocksModel.blocks.filter(item => item.type === 'paragraphBlock')[0]
    }
  },
  methods: {
    // ***************************************************************************************************************
    // Set structure of the current block
    // ***************************************************************************************************************
    setStructure (data, index) {
      this.editor.commands.setContent(data)
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: index,
        property: 'text',
        data: data
      })
    }
  },
  watch: {
    articleEditDisabled (disabled) {
      this.editor && this.editor.setEditable(!disabled)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.properties.text,
      dropCursor: { width: 0, color: 'transparent' },
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        },
        transformPastedHTML (html) {
          return html.replace(/<p[^>]*>/g, '')
            .replace(/<\/p>/g, '<br />')
            .replace(/<h[1-6][^>]*>/g, '')
            .replace(/<\/h[1-6]>/g, '<br />')
            .replace(/<div[^>]*>/g, '')
            .replace(/<\/div>/g, '<br />')
        }
      },
      onUpdate: ({ editor }) => {
        // ************************************************************************************************************
        // Update current block
        // ************************************************************************************************************
        if (editor.getJSON().content.length === 1) {
          this.outputText = editor.getHTML().replace(/<\/?p[^>]*>/g, '')
          this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
            index: this.index,
            property: 'text',
            data: this.outputText
          })
        }
        // ************************************************************************************************************
        // If You hit ENTER key
        // ************************************************************************************************************
        if (editor.getJSON().content.length === 2) {
          const arrayOfTheParagraphs = editor.getHTML().split(/<\/?p[^>]*>/g).filter(x => x)
          // *** Add new block before this block ***
          if (
            !Object.prototype.hasOwnProperty.call(editor.getJSON().content[0], 'content') &&
            Object.prototype.hasOwnProperty.call(editor.getJSON().content[1], 'content')
          ) {
            this.setStructure(arrayOfTheParagraphs[0], this.index)
            this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', this.index - 1)
            this.$store.commit('nmhEditorStore/SET_BLOCKS', {
              setNewBlock: JSON.parse(JSON.stringify(this.paragraphBlock))
            })
            return
          }
          // *** Add new block after this block ***
          if (
            Object.prototype.hasOwnProperty.call(editor.getJSON().content[0], 'content') &&
            !Object.prototype.hasOwnProperty.call(editor.getJSON().content[1], 'content')
          ) {
            this.setStructure(arrayOfTheParagraphs[0], this.index)
            this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', this.index)
            this.$store.commit('nmhEditorStore/SET_BLOCKS', {
              setNewBlock: JSON.parse(JSON.stringify(this.paragraphBlock))
            })
            return
          }
          // *** Split this block by current cursor position ***
          if (
            Object.prototype.hasOwnProperty.call(editor.getJSON().content[0], 'content') &&
            Object.prototype.hasOwnProperty.call(editor.getJSON().content[1], 'content')
          ) {
            arrayOfTheParagraphs.forEach((item, index) => {
              if (index === 0) {
                this.setStructure(item, this.index)
                return
              }
              this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', (this.index - 1) + index)
              const block = JSON.parse(JSON.stringify(this.paragraphBlock))
              block.properties.text = item
              this.$store.commit('nmhEditorStore/SET_BLOCKS', { setNewBlock: block })
            })
          }
          // *** Set empty content ***
          if (
            !Object.prototype.hasOwnProperty.call(editor.getJSON().content[0], 'content') &&
            !Object.prototype.hasOwnProperty.call(editor.getJSON().content[1], 'content')
          ) {
            this.setStructure('', this.index)
          }
        }
      },
      extensions: [
        ParagraphCustom,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          document: true,
          hardBreak: true,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          paragraph: true,
          text: true,
          // Marks
          bold: true,
          code: true,
          italic: true,
          strike: true,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        ...allTextExtensions,
        Underline,
        Placeholder.configure({
          placeholder: this.$t('nmhEditor.blocks.paragraphBlock.placeholder')
        }),
        CharacterCount.configure({
          limit: this.characterLimit
        })
      ]
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>
