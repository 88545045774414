<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="formatOptions"
    :editor="editor"
  >
    <EditorContent :editor="editor" />
  </Block>
</template>

<script>
import { Editor, EditorContent, Node } from '@tiptap/vue-2'
import Block from '@/components/nmhEditor/blocks/Block'
import StarterKit from '@tiptap/starter-kit'
import { allTextExtensions, allTextToolbarFormatOptions } from '@/services/nmhEditor/NmhEditorService'
import { mapState } from 'vuex'
import { generateHTML } from '@tiptap/html'
import Underline from '@tiptap/extension-underline'
import NotifyService from '@/services/NotifyService'

const ListCustom = Node.create({
  name: 'listCustom',
  topNode: true,
  content: 'block'
})

export default {
  name: 'OrderedOrBulletListBlock',
  components: {
    Block,
    EditorContent
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      emptyContent: '<p></p>',
      formatOptions: ['bold', 'italic', 'strike', 'underline', 'htmlIdAttr', ...allTextToolbarFormatOptions],
      extensions: [
        ListCustom,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          codeBlock: false,
          document: true,
          hardBreak: true,
          heading: false,
          horizontalRule: false,
          listItem: true,
          orderedList: true,
          bulletList: true,
          paragraph: true,
          text: true,
          // Marks
          bold: true,
          code: false,
          italic: true,
          strike: true,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        ...allTextExtensions,
        Underline
      ]
    }
  },
  computed: {
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      articleEditDisabled: state => state.article.articleEditDisabled
    }),
    rootHtmlTag () {
      return this.blockType === 'orderedListBlock' ? 'ol' : 'ul'
    }
  },
  methods: {
    getInitialStructure (data) {
      let liItems = ''
      if (Array.isArray(data)) {
        liItems = data.reduce((acc, item) => `${acc}<li>${item}</li>`, '')
      } else {
        liItems = `<li>${data ?? ''}</li>`
      }
      return `<${this.rootHtmlTag}>${liItems}</${this.rootHtmlTag}>`
    },
    setInitialStructure (data) {
      this.editor.commands.setContent(this.getInitialStructure(data))
    },
    convertContentToJsonHtmlMix () {
      const unsupportedError = (type) => {
        const error = `Unsupported content, item cannot be "${type}" type.`
        console.error(error)
        NotifyService.setErrorMessage(error)
      }
      const jsonContent = this.editor.getJSON().content
      const rootItems = jsonContent[0].content
      const listType = jsonContent[0]?.type
      if (jsonContent?.length !== 1 || !['orderedList', 'bulletList'].includes(listType)) {
        unsupportedError(listType)
        return []
      }
      const newBlockType = listType === 'orderedList' ? 'orderedListBlock' : 'unorderedListBlock'
      const convertedList = rootItems.map(listItem => {
        if (listItem.type !== 'listItem') {
          unsupportedError(listItem.type)
          return ''
        } else {
          return generateHTML(listItem, this.extensions)
            .replace(/<\/?p[^>]*>/g, '')
            .replace(/<\/?div[^>]*>/g, '')
        }
      })
      return { convertedList, newBlockType }
    }
  },
  watch: {
    articleEditDisabled (disabled) {
      this.editor && this.editor.setEditable(!disabled)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.getInitialStructure(this.properties.items),
      dropCursor: { width: 0, color: 'transparent' },
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      onUpdate: ({ editor }) => {
        if (editor.getHTML() === this.emptyContent) {
          this.setInitialStructure()
        }
        if (!editor.getHTML().includes('<li>') && editor.getHTML() !== this.emptyContent) {
          this.setInitialStructure(editor.getHTML())
        }
        const { convertedList, newBlockType } = this.convertContentToJsonHtmlMix()
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'items',
          data: convertedList,
          type: newBlockType
        })
      },
      extensions: this.extensions
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>
