<template>
  <div class="format-toolbar">
    <div class="format-toolbar__content">
      <div
        v-if="showOption('undo')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--disabled': !editor.can().undo() }"
        @click="editor.chain().focus().undo().run()"
        :title="$t('nmhEditor.tooltips.undo')"
      >
        <i class="fas fa-reply"></i>
      </div>
      <div
        v-if="showOption('redo')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--disabled': !editor.can().redo() }"
        @click="editor.chain().focus().redo().run()"
        :title="$t('nmhEditor.tooltips.redo')"
      >
        <i class="fas fa-reply fa-flip-horizontal"></i>
      </div>
      <template v-if="showOption('headings')">
        <div
          v-for="definition in headingLevels[company.toLowerCase()]"
          :key="definition"
          role="button"
          class="format-toolbar__btn format-toolbar__btn--heading"
          :class="{ 'format-toolbar__btn--active': editor.isActive('heading', { level: definition }) }"
          @click="editor.chain().focus().setHeading({ level: definition }).run()"
          :title="$t('nmhEditor.tooltips.headingTitle', { level: definition })"
        >
          H{{ definition }}
        </div>
      </template>
      <div
        v-if="showOption('bold')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
        :title="$t('nmhEditor.tooltips.bold')"
      >
        <SvgIconBold class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('italic')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
        :title="$t('nmhEditor.tooltips.italic')"
      >
        <SvgIconItalic class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('underline')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()"
        :title="$t('nmhEditor.tooltips.underline')"
      >
        <SvgIconUnderline class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('strike')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()"
        :title="$t('nmhEditor.tooltips.strike')"
      >
        <SvgIconStrike class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('insertedTag')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('insertedTag') }"
        @click="toggleInsertedTag"
        :title="$t('nmhEditor.tooltips.insertedTag')"
      >
        <ins><strong>{{ `ins` }}</strong></ins>
      </div>
      <div
        v-if="showOption('subscript')"
        role="button"
        class="format-toolbar__btn format-toolbar__btn--subscript"
        :class="{ 'format-toolbar__btn--active': editor.isActive('subscript') }"
        @click="editor.chain().focus().toggleSubscript().run()"
        :title="$t('nmhEditor.tooltips.subscript')"
      >
        X<sub>2</sub>
      </div>
      <div
        v-if="showOption('superscript')"
        role="button"
        class="format-toolbar__btn format-toolbar__btn--superscript"
        :class="{ 'format-toolbar__btn--active': editor.isActive('superscript') }"
        @click="editor.chain().focus().toggleSuperscript().run()"
        :title="$t('nmhEditor.tooltips.superscript')"
      >
        X<sup>2</sup>
      </div>
      <div
        v-if="showOption('bulletList')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()"
        :title="$t('nmhEditor.tooltips.link')"
      >
        <SvgIconUnorderedList class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('orderedList')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('orderedList') }"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :title="$t('nmhEditor.tooltips.link')"
      >
        <SvgIconOrderedList class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('link')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('link') }"
        @click="clickHandlerLink"
        :title="$t('nmhEditor.tooltips.link')"
      >
        <SvgIconLink class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('link') && editor.isActive('link')"
        role="button"
        class="format-toolbar__btn"
        @click="clickHandlerUnlink"
        :title="$t('nmhEditor.tooltips.unlink')"
      >
        <SvgIconUnlink class="format-toolbar__icon format-toolbar__icon--remove" />
      </div>
      <div
        v-if="formatOptions.includes('htmlIdAttr')"
        :class="[
            'format-toolbar__btn format-toolbar__btn--html-id-attr',
             { 'format-toolbar__btn--html-id-attr-active' : htmlId.length }
          ]"
        :title="$t('nmhEditor.tooltips.htmlIdAttr')"
        role="button"
        @click="clickHandlerHtmlIdAttr"
      >
        # <span v-if="htmlId.length" class="format-toolbar__html-id-code">{{ htmlId }}</span>
        <IconCopy
          v-if="htmlId.length"
          class="format-toolbar__btn--html-id-attr__icon"
          @click="copyHtmlId"
        />
      </div>
      <div
        v-if="showOption('inlineQuote')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('inlineQuote') }"
        @click="clickHandlerInlineQuote"
        :title="$t('nmhEditor.tooltips.inlineQuote')"
      >
        <span class="format-toolbar__inline-quote-icon">
          {{ `„“` }}
        </span>
      </div>
      <div
        v-if="showOption('code')"
        role="button"
        class="format-toolbar__btn"
        :class="{ 'format-toolbar__btn--active': editor.isActive('code') }"
        @click="editor.chain().focus().toggleCode().run()"
        :title="$t('nmhEditor.tooltips.code')"
      >
        <SvgIconCode class="format-toolbar__icon" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.insertRowBefore')"
      >
        <SvgInsertRowBefore @click="editor.chain().focus().addRowBefore().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.insertRowAfter')"
      >
        <SvgInsertRowAfter @click="editor.chain().focus().addRowAfter().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.deleteRow')"
      >
        <SvgDeleteRow  @click="editor.chain().focus().deleteRow().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.insertColumnBefore')"
      >
        <SvgInsertColumnBefore @click="editor.chain().focus().addColumnBefore().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.insertColumnAfter')"
      >
        <SvgInsertColumnAfter @click="editor.chain().focus().addColumnAfter().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.deleteColumn')"
      >
        <SvgDeleteColumn @click="editor.chain().focus().deleteColumn().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.mergeCells')"
      >
        <SvgMergeCells @click="editor.chain().focus().mergeCells().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.splitCells')"
      >
        <SvgSplitCells @click="editor.chain().focus().splitCell().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table format-toolbar__btn--table-tog-col"
        role="button"
        :title="$t('nmhEditor.tooltips.table.toggleHeaderColumn')"
      >
        <SvgToggleHeaderColumn @click="editor.chain().focus().toggleHeaderColumn().run()" />
      </div>
      <div
        v-if="showOption('table')"
        class="format-toolbar__btn format-toolbar__btn--table"
        role="button"
        :title="$t('nmhEditor.tooltips.table.toggleHeaderRow')"
      >
        <SvgToggleHeaderRow @click="editor.chain().focus().toggleHeaderRow().run()" />
      </div>
      <div
        v-if="showOption('alignment')"
        class="format-toolbar__btn format-toolbar__btn--alignment"
        :class="{ 'format-toolbar__btn--active': editor.isActive({ textAlign: 'left' }) }"
        role="button"
        :title="$t('nmhEditor.tooltips.align.left')"
      >
        <i
          @click="editor.chain().focus().setTextAlign('left').run()"
          class="format-toolbar__btn fa fa-align-left"
        >
        </i>
      </div>
      <div
        v-if="showOption('alignment')"
        class="format-toolbar__btn format-toolbar__btn--alignment"
        :class="{ 'format-toolbar__btn--active': editor.isActive({ textAlign: 'center' }) }"
        role="button"
        :title="$t('nmhEditor.tooltips.align.center')"
      >
        <i
          @click="editor.chain().focus().setTextAlign('center').run()"
          class="format-toolbar__btn fa fa-align-center"
        >
        </i>
      </div>
      <div
        v-if="showOption('alignment')"
        class="format-toolbar__btn format-toolbar__btn--alignment"
        :class="{ 'format-toolbar__btn--active': editor.isActive({ textAlign: 'right' }) }"
        role="button"
        :title="$t('nmhEditor.tooltips.align.right')"
      >
        <i
          @click="editor.chain().focus().setTextAlign('right').run()"
          class="format-toolbar__btn fa fa-align-right"
        >
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import IconCopy from '@/assets/img/svg/copy.svg?inline'
import SvgIconBold from '@/assets/img/svg/bold.svg?inline'
import SvgIconCode from '@/assets/img/svg/code.svg?inline'
import SvgIconItalic from '@/assets/img/svg/italic.svg?inline'
import SvgIconLink from '@/assets/img/svg/link.svg?inline'
import SvgIconUnlink from '@/assets/img/svg/link-slash-solid.svg?inline'
import SvgIconStrike from '@/assets/img/svg/strike.svg?inline'
import SvgIconUnderline from '@/assets/img/svg/underline.svg?inline'
import SvgInsertRowBefore from '@/assets/img/svg/table/insert-row-before.svg?inline'
import SvgInsertRowAfter from '@/assets/img/svg/table/insert-row-after.svg?inline'
import SvgDeleteRow from '@/assets/img/svg/table/delete-row.svg?inline'
import SvgInsertColumnBefore from '@/assets/img/svg/table/insert-column-before.svg?inline'
import SvgInsertColumnAfter from '@/assets/img/svg/table/insert-column-after.svg?inline'
import SvgDeleteColumn from '@/assets/img/svg/table/delete-column.svg?inline'
import SvgMergeCells from '@/assets/img/svg/table/merge-cells.svg?inline'
import SvgSplitCells from '@/assets/img/svg/table/split-cells.svg?inline'
import SvgToggleHeaderRow from '@/assets/img/svg/table/toggle-header-row.svg?inline'
import SvgToggleHeaderColumn from '@/assets/img/svg/table/toggle-header-column.svg?inline'
import SvgIconUnorderedList from '@/assets/img/svg/unordered-list.svg?inline'
import SvgIconOrderedList from '@/assets/img/svg/ordered-list.svg?inline'
import { mapGetters, mapState } from 'vuex'
import ClipboardService from '@/services/ClipboardService'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'FormatToolbar',
  components: {
    IconCopy,
    SvgIconBold,
    SvgIconCode,
    SvgIconItalic,
    SvgIconLink,
    SvgIconUnlink,
    SvgIconStrike,
    SvgIconUnderline,
    SvgInsertRowBefore,
    SvgInsertRowAfter,
    SvgDeleteRow,
    SvgInsertColumnBefore,
    SvgInsertColumnAfter,
    SvgDeleteColumn,
    SvgMergeCells,
    SvgSplitCells,
    SvgToggleHeaderRow,
    SvgToggleHeaderColumn,
    SvgIconUnorderedList,
    SvgIconOrderedList
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: false
    },
    /**
     * Available formatting
     * @values alignment, bold, code, headings, italic, link, inlineQuote, strike, underline, htmlIdAttr, subscript, superscript, table
     */
    formatOptions: {
      type: Array,
      required: true
    },
    /**
     * Editor instance
     */
    editor: {
      type: null,
      required: false
    }
  },
  data () {
    return {
      urlSetting: '',
      linkDropdown: false
    }
  },
  computed: {
    ...mapGetters(['company']),
    ...mapState({
      blocks: state => state.nmhEditorStore.data.blocks,
      headingLevels: state => state.nmhEditorStore.companySpecificSettings.headingLevels,
      hiddenFormatOptions: state => state.nmhEditorStore.companySpecificSettings.hiddenFormatOptions
    }),
    htmlId () {
      return this.blocks[this.index].properties.htmlId ?? ''
    }
  },
  methods: {
    showOption (option) {
      if (!this.editor) {
        return false
      }
      if (['orderedList', 'bulletList'].includes(option)) {
        return ['orderedListBlock', 'unorderedListBlock'].includes(this.blockType)
      }
      const hiddenFormatOptions = this.hiddenFormatOptions[this.company.toLowerCase()]
      return !hiddenFormatOptions.includes(option) && this.formatOptions.includes(option)
    },
    clickHandlerLink () {
      this.$store.commit('nmhEditorStore/SET_MODAL_LINK', true)
      this.$store.commit('nmhEditorStore/SET_MODAL_LINK_EDITOR', this.editor)
    },
    clickHandlerUnlink () {
      this.editor.chain().focus().unsetLink().run()
    },
    clickHandlerInlineQuote () {
      this.$store.commit('nmhEditorStore/SET_MODAL_INLINE_QUOTE', true)
      this.$store.commit('nmhEditorStore/SET_MODAL_INLINE_QUOTE_EDITOR', this.editor)
    },
    clickHandlerHtmlIdAttr () {
      this.$store.commit('nmhEditorStore/SET_MODAL_HTML_ID_ATTR', true)
    },
    toggleInsertedTag () {
      if (this.editor.isActive('insertedTag')) {
        this.editor.commands.unsetMark('insertedTag', { extendEmptyMarkRange: false })
        this.editor.chain().focus().run()
      } else {
        this.editor.chain().focus().extendMarkRange('insertedTag').setMark('insertedTag').run()
      }
    },
    copyHtmlId (event) {
      const htmlId = `#${this.htmlId}`
      ClipboardService.copy(htmlId, event)
      NotifyService.setSuccessMessage(this.$t('notify.id_was_copied'))
    }
  }
}
</script>

<style scoped lang="scss">
  .format-toolbar {
    @include size(100% _);
    @include sticky(136px, 1);
    left: rem(9px);
    margin-top: rem(-37px);
    padding-bottom: rem(7px);
    @include bp(0 4) {
      max-width: 200px;
    }
    @include bp(4 7) {
      max-width: 250px;
      width: fit-content;
    }
    @include bp(12) {
      @include sticky(110px, 1);
    }
    &__content {
      @include radius(4px);
      box-shadow: 0 0 10px 0px #d4d4d4;
      width: max-content;
      height: rem(30px);
      border: 1px solid #D1DBE4;
      background: #fff;
      display: flex;
      align-items: center;
      overflow: hidden;
      @include bp(0 7) {
        width: 100%;
        flex-wrap: wrap;
        min-height: rem(30px);
        height: auto;
        padding-top: .2rem;
        padding-bottom: .2rem;
      }
      &::after {
        @include absolute(26px _ _ 0);
        @include size(100% 8px);
        content: '';
        background: transparent;
      }
    }
    &__btn {
      @include padding(_ 4px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: none;
      align-self: stretch;
      &:not(.format-toolbar__btn--disabled):hover {
        background: #dbe7ff;
        cursor: pointer;
      }
      &--heading {
        @include font(500 16px "Roboto");
      }
      &--html-id-attr {
        font-size: 1.2rem;
        font-weight: 500;
        &__icon {
          width: rem(18px);
          height: auto;
          fill: #9DAAC4;
          padding-left: 0.2rem;
        }
      }
      &--subscript,
      &--superscript {
        font-size: 1rem;
        font-weight: 500;
      }
      &--html-id-attr-active {
        color: #6599fe;
      }
      &--active {
        color: #6599fe;
        .format-toolbar__icon, .format-toolbar__inline-quote-icon {
          fill: #6599fe;
          color: #6599fe;
        }
      }
      &--disabled {
        color: #D1DBE4;
      }
    }
    &__html-id-code {
      font: 400 0.7rem / 1.2rem "Roboto";
      padding: 0 .4rem;
      border-radius: 0.3125rem;
      background: rgba(0, 0, 0, 0.05);
      color: #6599fe;
      border: 1px solid rgba(0, 0, 0, 0.09);
      white-space: pre-wrap;
      margin-left: 0.2rem;
      display: none;
      @media screen and (min-width: 600px) {
        display: inline-block;
      }
    }
    &__icon {
      @include size(16px);
      fill: #000;
      &--remove {
        @include size(20px);
        &:hover {
          fill: #FF3455;
        }
      }
    }
    &__inline-quote-icon {
      font-family: Georgia, serif;
      font-size: 135%;
      color: #000;
    }
    &__btn--table {
      display: flex;
      align-self: stretch;
    }
    &__btn--table-tog-col {
      padding: 0;
      svg {
        transform: rotate(-90deg);
      }
    }
  }
</style>
