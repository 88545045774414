<template>
  <div>
    <div v-if="disabled" class="disabled-separator" />
    <button
      v-else
      class="btn-add-block"
      :class="{'btn-add-block--separator': separator}"
      :data-index="index"
      @click.stop="clickHandler"
      @mouseover="hover"
      @mouseout="unhover"
    >
      <span v-if="separator" class="btn-add-block__horizontal-line"></span>
      <span
        class="btn-add-block__icon"
        :class="{'btn-add-block__icon--separator': separator}"
      >
        +
      </span>
      <EditorTooltip v-show="tooltip">
        {{ $t('nmhEditor.tooltips.addBlock') }}
      </EditorTooltip>
    </button>
  </div>
</template>

<script>
import EditorTooltip from '@/components/nmhEditor/EditorTooltip'
import { mapState } from 'vuex'
export default {
  name: 'BtnAddBlock',
  props: {
    atTheEnd: {
      type: Boolean,
      required: false,
      default: false
    },
    index: {
      type: Number,
      required: false
    },
    separator: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      tooltip: false,
      hovering: false
    }
  },
  components: {
    EditorTooltip
  },
  computed: {
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled
    })
  },
  methods: {
    clickHandler () {
      if (this.atTheEnd) {
        this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', 'atTheEnd')
        this.$store.commit('nmhEditorStore/SET_MODAL_SELECT_BLOCK', true)
        return
      }
      this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', this.index)
      this.$store.commit('nmhEditorStore/SET_MODAL_SELECT_BLOCK', true)
    },
    hover () {
      this.hovering = true
      setTimeout(() => {
        this.tooltip = this.hovering
      }, 1000)
    },
    unhover () {
      this.hovering = false
      this.tooltip = false
    }
  }
}
</script>

<style scoped lang="scss">
  .disabled-separator {
    height: 1rem;
  }
  .btn-add-block {
    @include padding(0);
    @include radius(4px);
    @include size(22px);
    @include relative(_);
    border: none;
    cursor: pointer;
    background: #6599fe;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 200ms;
    &:not(.btn-add-block--separator) {
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &--separator {
      @include margin(3px _);
      @include size(100% _);
      background: #fff;
      justify-content: flex-end;
      @include bp(0 11) {
        display: none;
      }
      @include bp(11) {
        opacity: 0;
      }
      &:hover {
        opacity: 1;
      }
      .block-container--last & {
        display: none;
      }
    }
    &__horizontal-line {
      @include absolute(50% _ _ 0);
      @include size(calc(100% - 32px) 2px);
      background: #6599fe;
    }
    &__icon {
      @include font(500 25px "Roboto");
      color: #fff;
      pointer-events: none;
      &--separator {
        @include relative(_, 1);
        @include size(22px);
        @include radius(4px);
        background: #6599fe;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>
