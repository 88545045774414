<template>
  <div class="card card-body">
    <h3 class="article-edit-header">
      {{ $t('article.ai_service.social_media_generator.card_title') }}
      <Tooltip :title="$t('article.ai_service.social_media_generator.card_title_tooltip')"/>
    </h3>
    <section>
      <label>
        {{ $t('article.ai_service.social_media_generator.post_length.title') }}
        <span class="required">*</span>
        <Tooltip :title="$t('article.ai_service.social_media_generator.post_length.title_tooltip')" text-align="left"/>
      </label><br>
      <CheckboxButton
        :value="formData.postLength === POST_LENGTH_ENUM.SHORT"
        @input="setPostLength(POST_LENGTH_ENUM.SHORT)"
        id="article_social_media_generator_post_length_short"
        data-test="article_social_media_generator_post_length_short"
        :label="$t('article.ai_service.social_media_generator.post_length.short')"
      />
      <CheckboxButton
        :value="formData.postLength === POST_LENGTH_ENUM.MEDIUM"
        @input="setPostLength(POST_LENGTH_ENUM.MEDIUM)"
        id="article_social_media_generator_post_length_medium"
        data-test="article_social_media_generator_post_length_medium"
        :label="$t('article.ai_service.social_media_generator.post_length.medium')"
      />
      <CheckboxButton
        :value="formData.postLength === POST_LENGTH_ENUM.LONG"
        @input="setPostLength(POST_LENGTH_ENUM.LONG)"
        id="article_social_media_generator_post_length_long"
        data-test="article_social_media_generator_post_length_long"
        :label="$t('article.ai_service.social_media_generator.post_length.long')"
      />
    </section>
    <section>
      <label>
        {{ $t('article.ai_service.social_media_generator.type_of_interaction.title') }}
        <span class="required">*</span>
        <Tooltip :title="$t('article.ai_service.social_media_generator.type_of_interaction.title_tooltip')" text-align="left"/>
      </label><br>
      <CheckboxButton
        :value="formData.interactionType === INTERACTION_TYPE_ENUM.DISCUSSION"
        @input="setInteractionType(INTERACTION_TYPE_ENUM.DISCUSSION)"
        id="article_editorialArticle"
        data-test="article_editorialArticle"
        :label="$t('article.ai_service.social_media_generator.type_of_interaction.discussion')"
      />
      <CheckboxButton
        :value="formData.interactionType === INTERACTION_TYPE_ENUM.READING"
        @input="setInteractionType(INTERACTION_TYPE_ENUM.READING)"
        id="article_flag_native"
        data-test="article_flag_native"
        :label="$t('article.ai_service.social_media_generator.type_of_interaction.reading')"
      />
      <CheckboxButton
        :value="formData.interactionType === INTERACTION_TYPE_ENUM.SHARING"
        @input="setInteractionType(INTERACTION_TYPE_ENUM.SHARING)"
        id="article_prArticle"
        data-test="article_prArticle"
        :label="$t('article.ai_service.social_media_generator.type_of_interaction.sharing')"
      />
    </section>
    <Checkbox
      v-model="formData.addPerexToPrompt"
      id="article_social_media_generator_add_perex_to_prompt"
      class="mt-2"
      data-test="article_social_media_generator_add_perex_to_prompt"
      :label="$t('article.ai_service.social_media_generator.add_perex_to_prompt')"
    />
    <AiGenerator
      type="socialMediaPost"
      class="mt-2"
      :submit-button-label="$t('article.ai_service.social_media_generator.generate_btn')"
      :article="article"
      :open-modal-with-results="true"
      :socialMediaPostParams="formData"
    />
  </div>
</template>

<script>

import AiGenerator from '@/components/ai/AiGenerator.vue'
import CheckboxButton from '@/components/form/inputs/InputCheckBox.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import { INTERACTION_TYPE_ENUM, POST_LENGTH_ENUM } from '@/model/ValueObject/SocialMediaPostGeneratorValues'
import Tooltip from '@/components/tooltip/Tooltip.vue'

export default {
  name: 'ArticleAiSocialMediaGenerator',
  components: {
    Tooltip,
    CheckboxButton,
    Checkbox,
    AiGenerator
  },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formData: {
        postLength: POST_LENGTH_ENUM.MEDIUM, // Default value
        interactionType: INTERACTION_TYPE_ENUM.READING, // Default value
        addPerexToPrompt: false //  Default value
      },
      POST_LENGTH_ENUM,
      INTERACTION_TYPE_ENUM
    }
  },
  methods: {
    setPostLength (length) {
      this.formData.postLength = length
    },
    setInteractionType (type) {
      this.formData.interactionType = type
    }
  }
}
</script>
