<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="true"
    :format-toolbar="formatOptions"
    :editor="editor"
  >
    <EditorContent :editor="editor" />
    <CharacterViewer
      v-if="editor"
      :character-count="editor.storage.characterCount.characters()"
      :character-limit="characterLimit"
    />
  </Block>
</template>

<script>
import { Editor, EditorContent, Node } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import Block from '@/components/nmhEditor/blocks/Block'
import CharacterViewer from '@/components/nmhEditor/CharacterViewer'
import { allTextExtensions, allTextToolbarFormatOptions } from '@/services/nmhEditor/NmhEditorService'
import { mapGetters, mapState } from 'vuex'

const HeadingCustom = Node.create({
  name: 'headingCustom',
  topNode: true,
  content: 'block'
})

export default {
  name: 'HeadingBlock',
  components: {
    Block,
    CharacterViewer,
    EditorContent
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      outputLevel: '',
      outputText: '',
      characterLimit: 255,
      formatOptions: ['headings', 'htmlIdAttr', ...allTextToolbarFormatOptions]
    }
  },
  computed: {
    ...mapGetters(['company']),
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      articleEditDisabled: state => state.article.articleEditDisabled,
      headingLevels: state => state.nmhEditorStore.companySpecificSettings.headingLevels
    })
  },
  watch: {
    articleEditDisabled (disabled) {
      this.editor && this.editor.setEditable(!disabled)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: `<${this.properties.headingType}>${this.properties.text}</${this.properties.headingType}>`,
      dropCursor: { width: 0, color: 'transparent' },
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      onUpdate: ({ editor }) => {
        this.outputLevel = editor.getJSON().content[0].attrs.level
        this.outputText = editor.getHTML().replace(/<\/?h[1-6][^>]*>/g, '')
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'headingType',
          data: `h${this.outputLevel}`
        })
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'text',
          data: this.outputText
        })
      },
      extensions: [
        HeadingCustom,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          document: true,
          hardBreak: true,
          heading: {
            levels: this.headingLevels[this.company.toLowerCase()]
          },
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          paragraph: false,
          text: true,
          // Marks
          bold: false,
          code: false,
          italic: false,
          strike: false,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        Placeholder.configure({
          placeholder: this.$t('nmhEditor.blocks.headingBlock.placeholder')
        }),
        ...allTextExtensions,
        CharacterCount.configure({
          limit: this.characterLimit
        })
      ]
    })
    if (Object.prototype.hasOwnProperty.call(this.properties, 'headingType')) {
      if (!this.properties.headingType.length) {
        this.editor.commands.setHeading({ level: 2 })
      }
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>
