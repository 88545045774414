<template>
  <button
      class="btn-remove-block"
      @click="clickHandler"
      @mouseover="hover"
      @mouseout="unhover"
  >
    <SvgIconBasket class="btn-remove-block__icon" />
    <EditorTooltip v-show="tooltip">
      {{ $t('nmhEditor.tooltips.deleteBlock') }}
    </EditorTooltip>
  </button>
</template>

<script>
import EditorTooltip from '@/components/nmhEditor/EditorTooltip'
import SvgIconBasket from '@/assets/img/svg/basket.svg?inline'
export default {
  name: 'BtnRemoveBlock',
  components: {
    EditorTooltip,
    SvgIconBasket
  },
  data () {
    return {
      tooltip: false,
      hovering: false
    }
  },
  methods: {
    clickHandler () {
      this.$store.commit('nmhEditorStore/SET_MODAL_DELETE_BLOCK', {
        isVisible: true,
        deleteAllBlocks: false
      })
    },
    hover () {
      this.hovering = true
      setTimeout(() => {
        this.tooltip = this.hovering
      }, 500)
    },
    unhover () {
      this.hovering = false
      this.tooltip = false
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-remove-block {
    @include padding(0);
    @include radius(4px);
    @include size(22px);
    @include relative(_);
    border: 1px solid #d1dbe4;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border 200ms;
    z-index: 1;
    &:hover {
      border: 1px solid #fc4b6c;
      .btn-remove-block__icon {
        fill: #fc4b6c;
      }
    }
    &__icon {
      @include size(12px);
      fill: #9daac4;
    }
  }
</style>
