<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="formatOptions"
    :editor="editor"
  >
    <EditorContent :editor="editor" @click.prevent />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import { TableHeader } from '../../extensions/TableHeader'
import { TableCell } from '../../extensions/TableCell'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { allTextExtensions, allTextToolbarFormatOptions } from '@/services/nmhEditor/NmhEditorService'
import { mapState } from 'vuex'

export default {
  name: 'PollBlock',
  components: {
    Block,
    EditorContent
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editor: null,
      formatOptions: ['alignment', 'htmlIdAttr', 'strike', 'underline', 'bold', 'italic', 'table', ...allTextToolbarFormatOptions]
    }
  },
  computed: {
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      articleEditDisabled: state => state.article.articleEditDisabled
    })
  },
  watch: {
    articleEditDisabled (disabled) {
      this.editor && this.editor.setEditable(!disabled)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.properties.tableContent.html,
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      onUpdate: ({ editor }) => {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'tableContent',
          data: {
            html: editor.getHTML(),
            json: JSON.stringify(editor.getJSON())
          }
        })
      },
      extensions: [
        Table.configure({
          resizable: false
        }),
        TableRow,
        TableHeader,
        TableCell,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          document: true,
          hardBreak: false,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          paragraph: true,
          text: true,
          // Marks
          bold: true,
          code: false,
          italic: true,
          strike: true,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        ...allTextExtensions,
        Underline,
        TextAlign.configure({
          types: ['tableCell', 'tableHeader']
        })
      ]
    })
    if (this.properties.tableContent.html === '') {
      this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
    }
  },
  beforeUnmount () {
    this.editor.destroy()
  }
}
</script>

<style scoped lang="scss">
.table-block {
}
</style>
