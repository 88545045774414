<template>
  <div>
    <a v-if="linkPreview" :href="url" target="_blank" class="link-preview">
      <div class="wrapper">
        <div class="card-img">
          <img :src="image" :alt="title" />
        </div>
        <div class="card-info">
          <div class="card-text">
            <h1>{{ title }}</h1>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
    </a>
    <div v-else>
      {{ this.url }}
    </div>
  </div>
</template>

<script>
import { isURL } from '@/filters'
import linkPreviewApi from '@/api/linkPreview'

export default {
  name: 'LinkPreview',
  props: {
    url: {
      type: String,
      required: true
    },
    mediaTypeRequirement: {
      type: String, // 'pinterestapp:pin', 'photo', ....
      required: false
    }
  },
  data () {
    return {
      /**
       * linkPreview: {
       *   url: '',
       *   title: '',
       *   siteName: '', // 'Pinterest' | 'Instagram' | ...
       *   description: '',
       *   mediaType: '', // 'pinterestapp:pin' | 'photo' | ...
       *   contentType: '', // 'text/html'
       *   images: [
       *     '' // url
       *   ],
       *   videos: [],
       *   favicons: [
       *     '' // site image url
       *   ]
       * }
       */
      linkPreview: null
    }
  },
  computed: {
    image () {
      return this.linkPreview?.images?.[0]
    },
    title () {
      return this.linkPreview?.title
    },
    description () {
      return this.linkPreview?.description
    }
  },
  methods: {
    async loadLinkPreview () {
      this.linkPreview = null
      try {
        if (isURL(this.url)) {
          const response = await linkPreviewApi().post('/preview_link', { link: this.url })
          const linkPreview = response.data.preview
          if (!this.mediaTypeRequirement ||
            linkPreview?.mediaType?.toLowerCase() === this.mediaTypeRequirement.toLowerCase()) {
            this.linkPreview = linkPreview
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    url () {
      this.loadLinkPreview()
    }
  },
  mounted () {
    this.loadLinkPreview()
  }
}
</script>

<style lang="scss" scoped>
.link-preview {
  position: relative;

  /deep/ {
    .wrapper {
      overflow: auto;
      border-radius: 7px 7px 7px 7px;
      background-color: #fff;
      display: grid;
      width: 100%;
      max-width: 500px;
      box-shadow: none;
      margin-left: auto;
      margin-right: auto;
    }
    .card-img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 0;
        vertical-align: middle;
        border-style: none;
      }
    }

    .card-info {
      border-radius: 0 0 7px 7px;
      background-color: #ffffff;
    }
    .card-text {
      text-align: justify;
      padding: 1rem;
      margin: 0;
      width: 100%;
      h1, p {
        text-align: left;
        margin: 0;
        padding: 0;
      }
      h1 {
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: rem(17px);
        font-weight: 500;
        line-height: 1.3rem;
        color: #465674;
        margin-bottom: 0.5rem;
        @include ellipsis(2);
      }
      p {
        color: #8d8d8d;
        overflow: hidden;
        margin: 0;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: rem(14px);
        font-weight: 400;
        line-height: 1.2rem;
        @include ellipsis(2);
      }
    }
  }
}
</style>
