export const ARTICLE_TEST_CAR = 'car'
export const ARTICLE_TEST_RESTAURANT = 'restaurant'
export const ARTICLE_TEST_HOTEL = 'hotel'

export default {
  computed: {
    articleTestDiscriminatorsValues () {
      return [
        {
          id: ARTICLE_TEST_CAR,
          title: this.$t('article.test_discriminator.car')
        },
        {
          id: ARTICLE_TEST_RESTAURANT,
          title: this.$t('article.test_discriminator.restaurant')
        },
        {
          id: ARTICLE_TEST_HOTEL,
          title: this.$t('article.test_discriminator.hotel')
        }
      ]
    },
    articleTestDiscriminatorsAutobild () {
      return [
        {
          id: ARTICLE_TEST_CAR,
          title: this.$t('article.test_discriminator.car')
        }
      ]
    },
    articleTestDiscriminatorsCarEmbed () {
      return [
        {
          id: ARTICLE_TEST_CAR,
          title: this.$t('article.test_discriminator.car')
        }
      ]
    },
    articleTestDiscriminatorsEnjoy () {
      return [
        {
          id: ARTICLE_TEST_RESTAURANT,
          title: this.$t('article.test_discriminator.restaurant')
        },
        {
          id: ARTICLE_TEST_HOTEL,
          title: this.$t('article.test_discriminator.hotel')
        }
      ]
    }
  }
}
