<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="imageHasId"
    :click-handler-for-edit-btn="showEditModal"
  >
    <!--  When we wait for the data  -->
    <p v-if="loading">{{ $t('nmhEditor.loading') }}</p>

    <!--  If we don't have any data  -->
    <div v-if="!imageHasId" class="image-block__no-content-wrapper">
      <ButtonMain :click-handler="showSearchModal">
        {{ $t('nmhEditor.blocks.imageBlock.selectPhoto') }}
      </ButtonMain>
      <MediaUploadButton
        :multiple="true"
        :site="currentSite"
        :media-usage-type="mediaUsageType"
        show-page-breaks-checkbox
        @add-page-breaks="addPageBreaks"
        @set-media="addMedia"
      >
        {{ $t('nmhEditor.blocks.imageBlock.uploadPhoto') }}
      </MediaUploadButton>
      <MediaUrlButton
        :btn-text="$t('nmhEditor.blocks.imageBlock.uploadPhotoFromUrl')"
        :site="currentSite"
        :media-usage-type="mediaUsageType"
        :font-awesome-icon="null"
        @set-media="addMedia"
      />
    </div>

    <!--  If we have some data  -->
    <div v-if="imageHasId && !loading" class="image-block__content-wrapper">
      <div class="image-block__inner-wr">
        <ButtonMain v-if="!disabled" :click-handler="deleteImage" class="image-block__delete-photo">
          {{ $t('nmhEditor.blocks.imageBlock.deletePhoto') }}
        </ButtonMain>
        <img loading="lazy" :src="imageData.url" class="image-block__img">
      </div>
      <div>
        <h4 v-if="imageData.imageCaption.length" class="image-block__title">
          {{ $t('dam.description') }}: {{ imageData.imageCaption }}
        </h4>
        <h4 v-if="imageData.imageAltText" class="image-block__title">
          {{ $t('dam.imageAltText') }}: {{ imageData.imageAltText }}
        </h4>
        <h5 v-if="imageData.imageAttribution.length" class="image-block__source">
          {{ $t('nmhEditor.blocks.imageBlock.author') }}: {{ imageData.imageAttribution }}
        </h5>
      </div>
    </div>
    <DamSearchModal
      v-if="searchModal"
      @close="hideSearchModal"
      :multiple="true"
      @show-media-edit-modal="showEditModalWithMedia"
    />
    <MediaEditModal
      v-if="editModal"
      :selectedMedia="media"
      :site="currentSite"
      :media-usage-type="mediaUsageType"
      :insert-button-label="insertButtonLabel"
      show-page-breaks-checkbox
      @add-page-breaks="addPageBreaks"
      @add-media="addMedia"
      @close="hideEditModal"
    />
  </Block>
</template>

<script>
import ButtonMain from '@/components/nmhEditor/btns/BtnMain'
import Block from '@/components/nmhEditor/blocks/Block'
import { createBlock } from '@/services/nmhEditor/NmhEditorService'
import CoreApi from '@/api/core'
import DamSearchModal from '@/components/shared/DamSearchModal'
import MediaEditModal from '@/components/shared/MediaEditModal'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaMixin from '@/components/mixins/Media'
import { mapGetters, mapState } from 'vuex'
import MediaService from '@/services/media/MediaService'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import { MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK } from '@/model/ValueObject/MediaUsageTypes'

export default {
  name: 'ImageBlock',
  mixins: [MediaMixin],
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonMain,
    Block,
    DamSearchModal,
    MediaEditModal,
    MediaUploadButton,
    MediaUrlButton
  },
  data () {
    return {
      loading: false,
      editModal: false,
      pageBreaks: false,
      searchModal: false,
      imageData: {
        url: '',
        imageCaption: '',
        imageAltText: '',
        imageAttribution: ''
      },
      media: [],
      mediaUsageType: MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK,
      insertButtonLabel: null
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled
    }),
    imageHasId () {
      const id = this.properties.damMediaId.toString()
      return id.length
    },
    currentSite () {
      return this.$store.getters['article/currentSiteId']
    }
  },
  methods: {
    async addMedia (data) {
      let index = this.index
      const addBlock = block => {
        this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', index)
        this.$store.commit('nmhEditorStore/SET_BLOCKS', { setNewBlock: block })
        index += 1
      }

      for (const item of data) {
        await this.loadImage(item.id)
        const imageBlock = createBlock('imageBlock')
        imageBlock.properties = {
          ...imageBlock.properties,
          damMediaId: item.id.toString(),
          damMediaUuid: item.entityUuid.toString()
        }
        addBlock(imageBlock)
        if (this.pageBreaks) {
          addBlock(createBlock('pageBreakBlock'))
        }
      }
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        deleteSingleBlockProgrammatically: this.index
      })
    },
    addPageBreaks (pageBreaks) {
      this.pageBreaks = pageBreaks
    },
    deleteImage () {
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'damMediaId',
        data: ''
      })
    },
    async loadImage (coreMediaId) {
      this.loading = true
      try {
        const coreApiMedia = (await CoreApi().get('/image/' + coreMediaId)).data
        this.media.push(coreApiMedia)
        this.imageData.url = this.getDamImagePath(coreApiMedia)
        this.imageData.imageCaption = coreApiMedia.imageCaption
        this.imageData.imageAltText = coreApiMedia.imageAltText
        this.imageData.imageAttribution = coreApiMedia.imageAttribution
      } catch (error) { console.error(error) } finally { this.loading = false }
    },
    hideEditModal () {
      this.editModal = false
    },
    hideSearchModal () {
      this.searchModal = false
    },
    setMedia (assets) {
      this.media = []
      assets.forEach(asset => {
        this.media.push(MediaService.mapDamAssetToCoreMedia(asset))
      })
    },
    showEditModalWithMedia (media) {
      this.setMedia(media)
      this.searchModal = false
      this.insertButtonLabel = null
      this.editModal = true
    },
    showEditModal () {
      this.insertButtonLabel = this.$t('modal.done')
      this.editModal = true
    },
    showSearchModal () {
      this.searchModal = true
    }
  },
  mounted () {
    if (this.imageHasId) {
      try {
        this.loadImage(this.properties.damMediaId)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .image-block {
    &__content-wrapper {
      @include relative(_);
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      @include bp(0 14) {
        margin: auto;
        max-width: 400px;
      }
      @include bp(14) {
        grid-template-columns: 300px auto;
      }
    }
    &__no-content-wrapper {
      @include grid-gap(10px);
      display: grid;
      justify-content: center;
      @include bp(7) {
        grid-template-columns: repeat(3, max-content);
      }
    }
    &__inner-wr {
      position: relative;
    }
    &__img {
      @include radius(6px);
    }
    &__title {
      @include font(400 14px 20px "Roboto");
      @include margin(0 0 8px 0);
      color: #8a96ac;
      font-style: italic;
    }
    &__source {
      @include font(500 14px "Roboto");
      font-style: italic;
      color: #7e8695;
    }
    &__delete-photo {
      @include absolute(0 _ _ 0, 1);
      background: #fc4b6c;
      &:hover {
        background: darken(#fc4b6c, 10%);
      }
    }
  }
</style>
