<template>
  <app-modal
    :title="$t('article.modal.test_header')"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        v-if="tests.length && multiple"
        @click="addTests"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="test_btn_select"
        :disabled="selectedTests.length === 0"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <app-article-test-filter
        :discriminators="discriminators"
        class="m-b-10"
        disable-status-filter
      ></app-article-test-filter>
      <app-preloader v-if="callingAPI"></app-preloader>
      <template v-if="dataLoaded">
        <app-data-table
          :data="tests"
          :totalCount="totalCount"
          :page="page"
          :config="dataTableConfig"
          :selectable="multiple"
          actions-in-blank-window
          :disable-multiple-select=!multiple
          :maxSelected="maxSelected"
          :showSelectButton="!multiple"
          :selectedRecords="selectedTests"
          @page-change="setPageAndGetRecords"
          @select-record="selectTests"
        >
        </app-data-table>
        <div v-if="multiple || selectedTests.length" class="selected-items-list-box border-top">
          <div class="selected-items-list m-t-10">
            <ul class="list-inline">
              <li
                class="p-l-0">
                {{ $t('modal.selected') }}
                <span v-if="maxSelected !== null"> (max {{ maxSelected }}):</span>
              </li>
              <li v-for="(test, index) in selectedTests" :key="`item-${index}`" :class="{ 'font-weight-bold': index === 0 }">
                {{ test.title }}
                &nbsp;(<span v-if="index === 0">{{ $t('car.main_car') }}, </span>ID:{{ test.id }})
                <a
                  @click="removeSelectedTest(test.id)"
                  class="remove-item"><i class="fas fa-times"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import DataTable from '../shared/DataTable'
import Modal from '../shared/Modal'
import Config from '../../config'
import ArticleTestFilter from './ArticleTestFilter'

export default {
  name: 'ArticleTestModal',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    maxSelected: {
      type: Number,
      default: null
    },
    discriminators: {
      type: Array
    },
    selectedData: {
      type: Object,
      default: () => {
        return { ids: [] }
      }
    }
  },
  data () {
    return {
      page: 1,
      search: {
        id: '',
        title: ''
      },
      dataTableConfig: {},
      filterDiscriminator: null,
      selectedTests: [],
      dataLoaded: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    tests () {
      return this.$store.getters['articleTest/list']
    },
    totalCount () {
      return this.$store.getters['articleTest/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['articleTest/totalCount'] / Config.defaults.limit)
    }
  },
  components: {
    appModal: Modal,
    appArticleTestFilter: ArticleTestFilter,
    appDataTable: DataTable,
    appPreloader: Preloader
  },
  methods: {
    getTests () {
      this.$store.dispatch('articleTest/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('articleTest/setPage', this.page)
      this.getTests()
    },
    selectTests (tests) {
      this.selectedTests = tests
      if (this.multiple === false) {
        this.addTests()
      }
    },
    addTests () {
      this.$emit('set-data', this.selectedTests)
      this.$emit('close')
    },
    removeSelectedTest (id) {
      this.selectedTests = this.selectedTests.filter((test) => test.id !== id)
    },
    resetFilters () {
      const filter = {
        id: '',
        title: '',
        discriminator: this.filterDiscriminator
      }
      this.$store.commit('articleTest/setPage', 1)
      this.$store.commit('articleTest/setFilter', filter)
      this.getTests()
    },
    initSelectedData () {
      if (this.selectedData.ids.length) {
        const filter = {
          id: '',
          ids: this.selectedData.ids.join(','),
          title: '',
          discriminator: this.filterDiscriminator
        }
        this.$store.commit('articleTest/setPage', 1)
        this.$store.commit('articleTest/setFilter', filter)
        this.$store.dispatch('articleTest/fetch')
          .then(() => {
            const order = []
            this.selectedData.ids.forEach(function (a, i) {
              order[a] = i
            })
            this.tests.sort(function (a, b) {
              return order[a.id] - order[b.id]
            })
            this.selectedTests = this.tests
            this.resetFilters()
          })
      } else {
        this.resetFilters()
      }
    },
    close () {
      this.$emit('close')
    },
    discriminatorReset () {
      if (this.filterDiscriminator === null) {
        this.filterDiscriminator = this.discriminators[0].id
      }
    },
    initDataTableConfig () {
      this.discriminatorReset()
      this.dataTableConfig = {
        fields: {
          id: 'Id',
          title: 'Title',
          createdAt: 'Created at',
          createdBy: 'Created by'
        },
        actions: {
          detail: this.filterDiscriminator + '_detail',
          edit: this.filterDiscriminator + '_edit'
        }
      }
    }
  },
  created () {
    this.initDataTableConfig()
  },
  beforeMount () {
    this.initSelectedData()
  }
}
</script>

<style lang="scss" scoped>

a.remove-item {
  cursor: pointer;
  color: #fc4b6c;

  &:hover {
    color: #fc4b6c;
  }
}

.p-l-0 {
  padding-left: 0;
}

</style>
