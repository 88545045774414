<template class="generate-html-from-pr-article">
  <div class="generate-html-from-pr-article__container">
    <button
      class="generate-html-from-pr-article__btn"
      @click="getHtmlFromArticle"
      :disabled="loading"
    >
      {{ $t('article.generateHtmlFromPrArticle.btn') }} <template v-if="loading">...</template>
    </button>
    <ModalCopyContent
      v-if="modal"
      :content-data="responseContent"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import CoreApi from '@/api/core'
import ModalCopyContent from '@/components/modal/ModalCopyContent'

export default {
  name: 'GenerateHtmlFromPrArticle',
  components: {
    ModalCopyContent
  },
  props: {
    /**
     * Article Object
     */
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      responseContent: '',
      modal: false,
      loading: false
    }
  },
  methods: {
    closeModal () {
      this.modal = false
    },
    getHtmlFromArticle () {
      this.loading = true
      this.responseContent = ''
      CoreApi().get(`/prArticlePreview/${this.article.entityUuid}`)
        .then((res) => {
          this.responseContent = res.data.content
          this.loading = false
          this.modal = true
        })
        .catch((error) => {
          this.loading = false
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .generate-html-from-pr-article {
    &__container {
      display: inline-block;
    }
    &__btn {
      font-family: "Roboto", sans-serif;
      font-size: 0.8125rem;
      font-weight: 500;
      padding: 0.5rem 0.875rem;
      border-radius: 0.375rem;
      cursor: pointer;
      display: inline-block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
      &:disabled {
        pointer-events: none;
        opacity: .7;
      }
    }
  }
</style>
