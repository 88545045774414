<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="infoBoxHasId"
    :click-handler-for-edit-btn="showInfoBoxListModal"
  >
    <ButtonMain v-if="!infoBoxHasId" :click-handler="showInfoBoxListModal">
      {{ $t('nmhEditor.blocks.infoBoxBlock.empty') }}
    </ButtonMain>
    <div v-if="infoBoxHasId" class="info-box-block__not-empty-wrapper">
      <SvgIconInfoBox class="info-box-block__icon-infobox" />
      <span class="info-box-block__text">
        {{ $t('nmhEditor.blocks.infoBoxBlock.notEmpty') }} - {{ infoBoxData }}
      </span>
    </div>
    <InfoboxCreateModal
      v-if="infoBoxCreateModal"
      :selectedData="selectedData"
      @close="closeInfoBoxCreateModal"
      @set-data="setInfoBox"
      @show-infobox-create-modal="showInfoBoxCreateModal"
      @show-infobox-list-modal="showInfoBoxListModal"
    />
    <InfoboxListModal
      v-if="infoBoxListModal"
      :selectedData="selectedData"
      @close="closeInfoBoxListModal"
      @set-data="setInfoBox"
      @show-infobox-create-modal="showInfoBoxCreateModal"
      @show-infobox-list-modal="showInfoBoxListModal"
    />
  </Block>
</template>

<script>
import ButtonMain from '@/components/nmhEditor/btns/BtnMain'
import Block from '@/components/nmhEditor/blocks/Block'
import coreApi from '@/api/core'
import InfoboxCreateModal from '@/components/article/richtext/InfoboxCreateModal'
import InfoboxListModal from '@/components/article/richtext/InfoboxListModal'
import SvgIconInfoBox from '@/assets/img/svg/infobox.svg?inline'
export default {
  name: 'InfoBoxBlock',
  components: {
    ButtonMain,
    Block,
    InfoboxCreateModal,
    InfoboxListModal,
    SvgIconInfoBox
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      theModalIsVisible: false,
      selectedData: null,
      infoBoxId: null,
      infoBoxData: null,
      infoBoxCreateModal: false,
      infoBoxListModal: false
    }
  },
  computed: {
    infoBoxHasId () {
      return this.properties.infoBoxId !== ''
    }
  },
  methods: {
    closeInfoBoxCreateModal () {
      this.infoBoxCreateModal = false
    },
    closeInfoBoxListModal () {
      this.infoBoxListModal = false
    },
    loadInfoBoxData (id) {
      if (!isNaN(this.properties.infoBoxId)) {
        const url = '/infobox/' + id
        return coreApi().get(url)
          .then(response => {
            this.infoBoxData = response.data.title
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    setInfoBox (data) {
      this.infoBoxId = data.id
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'infoBoxId',
        data: this.infoBoxId
      })
      this.loadInfoBoxData(this.infoBoxId)
    },
    showInfoBoxCreateModal () {
      this.infoBoxCreateModal = true
      this.infoBoxListModal = false
    },
    showInfoBoxListModal () {
      this.infoBoxListModal = true
      this.infoBoxCreateModal = false
    }
  },
  mounted () {
    if (this.infoBoxHasId) {
      this.loadInfoBoxData(this.properties.infoBoxId)
    }
  }
}
</script>

<style scoped lang="scss">
  .info-box-block {
    &__not-empty-wrapper {
      @include grid-gap(9px);
      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
    }
    &__icon-infobox {
      @include size(16px);
    }
    &__text {
      @include font(400 18px "Roboto");
      @include margin(0);
      @include relative(-1px _ _);
      color: #465674;
    }
  }
</style>
