<template>
  <div class="nmh-editor" :class="{'nmh-editor--dragging': dragging}" :spellcheck="spellcheck">
    <div class="nmh-editor__right-side">
      <FloatingBar />
      <PlaceholderBlock
        v-if="!blocks.length"
        :placeholder="this.$t('nmhEditor.blocks.placeholderBlock.start')"
      />
      <Draggable
        v-if="blocks.length"
        :list="blocks"
        class="nmh-editor__blocks"
        handle=".btn-draggable-block"
        draggable=".draggable-block"
        ghost-class="block-container--ghost"
        @start="dragging = true"
        @end="dragging = false"
        v-bind="dragOptions"
        :scroll-sensitivity="200"
        :fallback-tolerance="1"
        :force-fallback="true"
      >
        <BlockContainer
          v-for="(block, index) in blocks"
          :key="block.id"
          :block-type="block.type"
          :properties="block.properties"
          :index="index"
          :position="block.position"
          :class="{ 'draggable-block': isDraggable(block), 'block-container--last': index === blocks.length - 1 }"
        />
      </Draggable>
      <PlaceholderBlock
        v-if="blocks.length"
        :placeholder="this.$tc('nmhEditor.blocks.placeholderBlock.end')"
      />
      <ModalCopyPaste v-if="copyPasteModal" />
      <ModalDeleteBlock v-if="deleteModal" />
      <ModalSelectBlock v-if="selectModal" />
      <ModalLink v-if="linkModal" :editor="linkModalForEditor" />
      <ModalInlineQuote v-if="inlineQuoteModal" :editor="inlineQuoteModalForEditor" />
      <ModalHtmlIdAttr v-if="htmlIdAttrModal" />
      <ModalThirdPartyEmbed v-if="thirdPartyModalEmbed" />
      <ModalSource v-if="sourceModal" />
    </div>
  </div>
</template>

<script>
import BlockContainer from '@/components/nmhEditor/BlockContainer'
import Draggable from 'vuedraggable'
import FloatingBar from '@/components/nmhEditor/FloatingBar'
import { mapState } from 'vuex'
import ModalLink from '@/components/nmhEditor/modals/ModalLink'
import ModalInlineQuote from '@/components/nmhEditor/modals/ModalInlineQuote'
import ModalDeleteBlock from '@/components/nmhEditor/modals/ModalDeleteBlock'
import ModalSelectBlock from '@/components/nmhEditor/modals/ModalSelectBlock'
import PlaceholderBlock from '@/components/nmhEditor/blocks/PlaceholderBlock'
import ModalCopyPaste from '@/components/nmhEditor/modals/ModalCopyPaste'
import ModalSource from '@/components/nmhEditor/modals/ModalSource'
import ModalHtmlIdAttr from '@/components/nmhEditor/modals/ModalHtmlIdAttr'
import ModalThirdPartyEmbed from '@/components/nmhEditor/modals/ModalThirdPartyEmbed'

export default {
  name: 'NmhEditor',
  components: {
    ModalCopyPaste,
    ModalSource,
    BlockContainer,
    Draggable,
    FloatingBar,
    ModalLink,
    ModalInlineQuote,
    ModalDeleteBlock,
    ModalSelectBlock,
    ModalThirdPartyEmbed,
    PlaceholderBlock,
    ModalHtmlIdAttr
  },
  props: {
    /**
     * Spellcheck is ON or OFF
     */
    spellcheck: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dragging: false
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description'
      }
    },
    ...mapState({
      blocks: state => state.nmhEditorStore.data.blocks,
      draggableDisabledBlocks: state => state.nmhEditorStore.draggableDisabledBlocks,
      copyPasteModal: state => state.nmhEditorStore.modals.copyPaste.isVisible,
      sourceModal: state => state.nmhEditorStore.modals.source.isVisible,
      deleteModal: state => state.nmhEditorStore.modals.deleteBlock.isVisible,
      selectModal: state => state.nmhEditorStore.modals.selectBlock.isVisible,
      linkModal: state => state.nmhEditorStore.modals.link.isVisible,
      htmlIdAttrModal: state => state.nmhEditorStore.modals.htmlIdAttr.isVisible,
      linkModalForEditor: state => state.nmhEditorStore.modals.link.selectedEditor,
      inlineQuoteModal: state => state.nmhEditorStore.modals.inlineQuote.isVisible,
      inlineQuoteModalForEditor: state => state.nmhEditorStore.modals.inlineQuote.selectedEditor,
      thirdPartyModalEmbed: state => state.nmhEditorStore.modals.thirdPartyEmbed.isVisible
    })
  },
  methods: {
    isDraggable (block) {
      return !this.draggableDisabledBlocks.includes(block.type)
    }
  },
  watch: {
    blocks () {
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        changeSingleBlockPosition: true
      })
    }
  }
}
</script>

<style lang="scss">
  .nmh-editor {
    @include margin(_ _ 30px);
    display: grid;
    &__title {
      display: flex;
    }
    &__title-text {
      @include font(500 17px "Roboto");
      color: #8a96ac;
      opacity: .7;
    }
    .flip-list-move {
      transition: transform 0.5s;
    }
    .no-move {
      transition: transform 0s;
    }
  }
</style>
