import { cloneDeep } from 'lodash'
import blocksModel from '@/model/nmhEditor/blocksModel'
import Link from '@tiptap/extension-link'
import { Mark, mergeAttributes } from '@tiptap/core'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'

const createBlock = blockType => {
  const foundBlock = blocksModel.blocks.find(item => item.type === blockType)
  if (!foundBlock) {
    throw Error(`Unsupported type: ${blockType}`)
  }
  return cloneDeep(foundBlock)
}

const CustomSuperscript = Superscript.extend({
  excludes: 'subscript'
})

const CustomSubscript = Subscript.extend({
  excludes: 'superscript'
})

// 'rel' attribute for Link cannot be changed outside 'configure()' function, so we need to extend the Link component
//   and then the 'rel' attribute is changed dynamically in ModalLink.vue
const CustomLink = Link.extend({
  addAttributes () {
    return {
      href: {
        default: this.options.HTMLAttributes.href
      },
      target: {
        default: this.options.HTMLAttributes.target
      },
      rel: {
        default: this.options.HTMLAttributes.rel
      }
    }
  },
  addOptions () {
    return {
      autolink: false,
      openOnClick: false,
      HTMLAttributes: { rel: 'noopener' }
    }
  }
})

const InlineQuoteMark = Mark.create({
  name: 'inlineQuote',
  addAttributes () {
    return {
      cite: {
        default: ''
      }
    }
  },
  renderHTML ({ HTMLAttributes }) {
    // merges 'cite' attribute in HTMLAttributes set in ModalInlineQuote.vue
    return ['q', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  parseHTML () {
    return [{ tag: 'q' }]
  }
})
const InsertedMark = Mark.create({
  name: 'insertedTag',
  renderHTML () {
    return ['ins']
  },
  parseHTML () {
    return [{ tag: 'ins' }]
  }
})

const allTextExtensions = [
  CustomSuperscript,
  CustomSubscript,
  CustomLink,
  InlineQuoteMark,
  InsertedMark
]

const allTextToolbarFormatOptions = [
  'undo',
  'redo',
  'subscript',
  'superscript',
  'link',
  'inlineQuote',
  'insertedTag'
]

export {
  createBlock,
  CustomSuperscript,
  CustomSubscript,
  CustomLink,
  InlineQuoteMark,
  allTextExtensions,
  allTextToolbarFormatOptions
}
