<template>
  <Block
    :block-type="blockType"
    :format-toolbar="['htmlIdAttr']"
    :index-of-the-block="index"
    :show-edit-btn="specialHasId"
    :click-handler-for-edit-btn="showModal"
  >
    <button v-if="isEditMode" class="special-block__btn-empty" @click="showModal">
      <span v-if="!loadedSpecial"> {{ $t('nmhEditor.blocks.specialArticleGroupBlock.empty') }} </span>
      <span v-else> {{ $t('nmhEditor.blocks.specialArticleGroupBlock.edit_btn') }} </span>
    </button>
    <div v-if="loadedSpecial && properties" class="display-wrapper">
      <div class="theme-col theme-display-left-col">
        <p> <span>{{ $t('themeSpecialAndSeries.name') }}:</span> {{ loadedSpecial.name }} </p>
        <p> <span>{{ $t('themeSpecialAndSeries.id') }}:</span> {{ loadedSpecial.id }} </p>
      </div>
      <div class="theme-col theme-display-right-col">
        <p> <span>{{ $t('themeSpecialAndSeries.select_display_type') }}:</span> {{ properties.display }} </p>
      </div>
    </div>
    <ArticleThemeSpecialAndSeries
      type="special"
      v-if="theModalIsVisible"
      @saveData="setThemeSpecialAndSeries"
      @close="hideModal"
    />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import ArticleThemeSpecialAndSeries from '@/components/article/ArticleThemeSpecialAndSeriesModal.vue'

export default {
  name: 'SpecialArticleGroupBlock',
  components: {
    Block,
    ArticleThemeSpecialAndSeries
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      theModalIsVisible: false,
      selectedData: null,
      seriesId: null,
      specialArticleGroupId: null,
      themeId: null,
      loadedSpecial: null
    }
  },
  computed: {
    specialHasId () {
      return this.properties.themeId !== ''
    },
    isEditMode () {
      return this.$route.path.endsWith('/edit')
    }
  },
  methods: {
    showModal () {
      this.theModalIsVisible = true
    },
    hideModal () {
      this.theModalIsVisible = false
    },
    getOneSpecial (id) {
      this.$store.dispatch('themeSpecialAndSeries/fetchOneSpecial', id)
        .then((res) => {
          this.loadedSpecial = res
        })
    },
    setThemeSpecialAndSeries (data) {
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'articlesCount',
        data: data.articlesCount
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'specialArticleGroupId',
        data: data.specialArticleGroupId
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'display',
        data: data.display
      })
      if (data) {
        this.getOneSpecial(data.specialArticleGroupId)
      }
    }
  },
  mounted () {
    if (this.properties.specialArticleGroupId) {
      this.getOneSpecial(this.properties.specialArticleGroupId)
    }
  }
}
</script>

<style scoped lang="scss">

.special-block {
  &__btn-empty {
    @include font(500 13px "Roboto");
    @include margin(_ auto);
    @include padding(8px 14px);
    @include radius(6px);
    cursor: pointer;
    display: block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__text {
    @include font(400 15px "Roboto");
    @include relative(_);
    @include padding(_ _ _ 15px);
    display: flex;
    align-items: center;
    &::before {
      @include absolute(5px _ _ 0);
      @include size(8px);
      @include radius(50%);
      background: #465674;
      content: '';
    }
  }
}

.display-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  padding-top: 0.5rem;

  .theme-col {
    span {
      font-weight: 400;
      color: #8A96AC;
      margin-right: 0.5rem;
    }
    &>p:last-child {
      margin: 0;
    }
  }
}

</style>
