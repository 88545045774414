<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="formatOptions"
    :editor="quoteEditor"
  >
    <blockquote class="quote-editor" @keydown="clickHandler">
      <EditorContent class="quote-editor__quote" :editor="quoteEditor" />
      <EditorContent class="quote-editor__author" :editor="authorEditor" />
    </blockquote>
  </Block>
</template>

<script>
import { Editor, EditorContent, Node } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Block from '@/components/nmhEditor/blocks/Block'
import { mapState } from 'vuex'
import { allTextExtensions, allTextToolbarFormatOptions } from '@/services/nmhEditor/NmhEditorService'

const QuoteCustom = Node.create({
  name: 'quoteCustom',
  topNode: true,
  content: 'block'
})

export default {
  name: 'QuoteBlock',
  components: {
    Block,
    EditorContent
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      quoteEditor: null,
      authorEditor: null,
      formatOptions: ['htmlIdAttr', ...allTextToolbarFormatOptions]
    }
  },
  computed: {
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      articleEditDisabled: state => state.article.articleEditDisabled
    })
  },
  methods: {
    clickHandler (e) {
      if (e.key === 'ArrowDown' || (e.key === 'Enter' && !e.shiftKey)) {
        this.$el.querySelector('.quote-editor__author .ProseMirror').focus()
      }
      if (e.key === 'ArrowUp') {
        this.$el.querySelector('.quote-editor .ProseMirror').focus()
      }
    }
  },
  watch: {
    articleEditDisabled (disabled) {
      this.quoteEditor && this.quoteEditor.setEditable(!disabled)
      this.authorEditor && this.authorEditor.setEditable(!disabled)
    }
  },
  mounted () {
    this.quoteEditor = new Editor({
      content: this.properties.text,
      dropCursor: { width: 0, color: 'transparent' },
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      onUpdate: ({ editor }) => {
        const outputText = editor.getHTML()
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'text',
          data: outputText
        })
      },
      extensions: [
        QuoteCustom,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          document: true,
          hardBreak: true,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          paragraph: true,
          text: true,
          // Marks
          bold: false,
          code: false,
          italic: false,
          strike: false,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        ...allTextExtensions,
        Placeholder.configure({
          placeholder: this.$t('nmhEditor.blocks.quoteBlock.text')
        })
      ]
    })
    this.authorEditor = new Editor({
      content: this.properties.author,
      dropCursor: { width: 0, color: 'transparent' },
      editable: !this.disabled,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => e.preventDefault()
        }
      },
      onUpdate: ({ editor }) => {
        const outputText = editor.getHTML().replace(/<(?!\s*br\s*\/?)[^>]+>/gi, '')
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'author',
          data: outputText
        })
      },
      extensions: [
        QuoteCustom,
        StarterKit.configure({
          // Nodes
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          document: true,
          hardBreak: true,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          paragraph: true,
          text: true,
          // Marks
          bold: false,
          code: false,
          italic: false,
          strike: false,
          // Extensions
          dropcursor: false,
          gapcursor: false,
          history: true
        }),
        Placeholder.configure({
          placeholder: this.$t('nmhEditor.blocks.quoteBlock.author')
        })
      ]
    })
  },
  beforeDestroy () {
    this.quoteEditor.destroy()
    this.authorEditor.destroy()
  }
}
</script>

<style scoped lang="scss">
  .quote-editor {
    @include padding(0 0 0 10px);
    @include margin(0);
    border: none;
    border-left: 3px solid #6599fe;
    &__quote {
      @include font(400 20px "Roboto");
      @include margin(_ _ 10px);
      opacity: .9;
    }
    &__author {
      @include font(400 15px "Roboto");
      opacity: .7;
    }
  }
</style>
