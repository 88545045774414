<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="youtubeHasId"
    :click-handler-for-edit-btn="showYoutubeModal"
  >
    <button v-if="!youtubeHasId" class="youtube-block__btn-empty" @click="showYoutubeModal">
      {{ $t('nmhEditor.blocks.youtubeBlock.empty') }}
    </button>
    <div v-if="youtubeHasId" class="youtube-block__preview-container">
      <div class="youtube-block__preview" v-html="preview.html" />
    </div>
    <YoutubeModal
      v-if="youtubeModal"
      :selectedData="selectedData"
      @close="hideYoutubeModal"
      @set-data="setData"
    />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import CoreApi from '@/api/core'
import YoutubeModal from '@/components/shared/YoutubeModal'
export default {
  name: 'YoutubeBlock',
  components: {
    Block,
    YoutubeModal
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedData: '',
      preview: {},
      youtubeId: '',
      youtubeModal: false
    }
  },
  computed: {
    youtubeHasId () {
      return this.properties.youtubeLink.length > 0
    }
  },
  methods: {
    showYoutubeModal () {
      this.selectedData = this.properties.youtubeLink
      this.youtubeModal = true
    },
    hideYoutubeModal () {
      this.youtubeModal = false
    },
    setData (data) {
      this.youtubeId = data
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'youtubeLink',
        data: this.youtubeId
      })
      this.loadYoutubeVideoById(this.youtubeId)
    },
    loadYoutubeVideoById (id) {
      CoreApi().post('/utils/youtube', JSON.stringify({ input: id }))
        .then(response => {
          this.preview = response.data.youtube
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  mounted () {
    if (this.youtubeHasId) {
      this.loadYoutubeVideoById(this.properties.youtubeLink)
    }
  }
}
</script>

<style lang="scss">
  .youtube-block {
    &__btn-empty {
      @include font(500 13px "Roboto");
      @include margin(_ auto);
      @include padding(8px 14px 8px);
      @include radius(6px);
      cursor: pointer;
      display: block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__not-empty-wrapper {
      @include margin(_ auto);
      width: 100%;
      max-width: 300px;
      display: block;
    }
    &__preview-container {
      @include margin(_ auto);
      width: 100%;
      max-width: 300px;
    }
    &__preview {
      @include relative(_);
      @include size(_ 0);
      @include padding(_ _ 56.25%); /* 16:9 */
      @include radius(6px);
      overflow: hidden;
      > iframe {
        @include absolute(0 _ _ 0);
        @include size(100%);
      }
    }
  }
</style>
