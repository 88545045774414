export const ARTICLE_LAYOUT_0 = 0
export const ARTICLE_LAYOUT_1 = 1
export const ARTICLE_LAYOUT_2 = 2
export const ARTICLE_LAYOUT_3 = 3

export const ARTICLE_LAYOUT_DEFAULT = ARTICLE_LAYOUT_0

export default {
  computed: {
    articleLayoutValues () {
      return [
        {
          id: ARTICLE_LAYOUT_0,
          title: this.$t('article.article_layout.layout_0')
        },
        {
          id: ARTICLE_LAYOUT_1,
          title: this.$t('article.article_layout.layout_1')
        },
        {
          id: ARTICLE_LAYOUT_2,
          title: this.$t('article.article_layout.layout_2')
        },
        {
          id: ARTICLE_LAYOUT_3,
          title: this.$t('article.article_layout.layout_3')
        }
      ]
    }
  }
}
