<template>
  <div class="modal-delete-block">
    <div v-click-outside="modalHandler" class="modal-delete-block__dialog-wr">
      <h4 class="modal-delete-block__title" v-html="deleteAllBlocks ? desc.all : desc.single"></h4>
      <div class="modal-delete-block__btn-area">
        <button class="modal-delete-block__btn" @click="clickHandlerClose">
          <SvgIconClose class="modal-delete-block__icon modal-delete-block__icon--close" />
          <span class="modal-delete-block__btn-desc">{{ desc.close }}</span>
        </button>
        <button class="modal-delete-block__btn" @click="clickHandlerDelete">
          <SvgIconBasket class="modal-delete-block__icon modal-delete-block__icon--delete" />
          <span class="modal-delete-block__btn-desc">{{ desc.delete }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgIconClose from '@/assets/img/svg/close.svg?inline'
import SvgIconBasket from '@/assets/img/svg/basket.svg?inline'
export default {
  name: 'ModalDeleteBlock',
  data () {
    return {
      desc: {
        all: this.$t('nmhEditor.modals.deleteBlock.title.all'),
        single: this.$t('nmhEditor.modals.deleteBlock.title.single'),
        close: this.$t('nmhEditor.btns.close'),
        delete: this.$t('nmhEditor.btns.delete')
      }
    }
  },
  components: {
    SvgIconClose,
    SvgIconBasket
  },
  computed: {
    ...mapState({
      deleteAllBlocks: state => state.nmhEditorStore.modals.deleteBlock.deleteAllBlocks,
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock
    })
  },
  methods: {
    clickHandlerClose () {
      this.modalHandler()
    },
    clickHandlerDelete () {
      if (this.deleteAllBlocks) {
        this.$store.commit('nmhEditorStore/SET_BLOCKS', {
          deleteAllBlocks: true
        })
      }
      if (!this.deleteAllBlocks) {
        this.$store.commit('nmhEditorStore/SET_BLOCKS', {
          deleteSingleBlock: true
        })
      }
      this.modalHandler()
    },
    modalHandler () {
      this.$store.commit('nmhEditorStore/SET_MODAL_DELETE_BLOCK', {
        isVisible: false
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-delete-block {
    @include fixed(0, 100);
    @include size(100% 100vh);
    @include padding(_ 10px);
    overflow-y: scroll;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &__dialog-wr {
      @include size(450px _);
      @include padding(50px);
      @include radius(6px);
      background: #f2f3f7;
      box-shadow: 0 0 30px 5px #8d8d8d;
    }
    &__title {
      @include font(400 18px "Roboto");
      @include margin(0);
      color: #465674;
      /deep/ strong {
        @include font(400 18px "Roboto");
        color: #c82829;
      }
    }
    &__btn-area {
      @include margin(25px _ _);
      @include padding(20px _ _);
      display: grid;
      grid-template-columns: repeat(2, min-content);
      justify-content: space-between;
      border-top: 1px solid #ddd;
    }
    &__btn {
      @include grid-gap(8px);
      display: grid;
      grid-template-columns: repeat(2, min-content);
      align-items: center;
      border: none;
      cursor: pointer;
      background: transparent;
      &:hover {
        .modal-delete-block__icon {
          transform: scale(1.2);
        }
      }
    }
    &__icon {
      @include size(18px);
      transition: all 200ms;
      transform: scale(1);
      &--close {
        fill: #111;
      }
      &--delete {
        fill: #c82829;
      }
    }
    &__btn-desc {
      @include font(400 16px "Roboto");
      color: #465674;
    }
  }
</style>
