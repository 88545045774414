<template>
  <app-modal
    :title="$t('article.modal.infobox_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row m-b-10">
        <div class="col-lg-12 text-right">
          <button class="btn btn-secondary" @click="showInfoboxCreateModal">
            {{ $t('article.modal.infobox_create') }}
          </button>
          <button class="btn btn-secondary active" @click="showInfoboxListModal">
            {{ $t('article.modal.infobox_select') }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <app-infobox-filter></app-infobox-filter>
          <div class="card">
            <div class="card-body">
              <app-preloader v-if="callingAPI"></app-preloader>
              <app-data-table
                :data="infoboxes"
                :totalCount="totalCount"
                :page="page"
                :config="dataTableConfig"
                selectable
                disableMultipleSelect
                @page-change="setPageAndGetRecords"
                @select-record="selectInfobox"
                actionsInBlankWindow
              >
              </app-data-table>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Sub header -->
    <template slot="sub-header">
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="insert" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import Preloader from '@/components/preloader/Preloader'
import InfoboxFilter from '@/components/filter/FilterInfobox'
import DataTable from '@/components/shared/DataTable'
import Modal from '@/components/shared/Modal'

export default {
  name: 'InfoboxListModal',
  data () {
    return {
      dataLoaded: false,
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('infobox.list.id'),
          title: this.$t('infobox.list.title'),
          heading: this.$t('infobox.list.heading'),
          createdAt: this.$t('infobox.list.created_at'),
          createdBy: this.$t('infobox.list.created_by')
        },
        actions: {
          detail: 'infobox_detail',
          edit: 'infobox_edit'
        }
      },
      selectedInfobox: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    infoboxes () {
      return this.$store.getters['infobox/list']
    },
    totalCount () {
      return this.$store.getters['infobox/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['infobox/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModal: Modal,
    appPreloader: Preloader,
    appInfoboxFilter: InfoboxFilter,
    appDataTable: DataTable
  },
  methods: {
    getInfoboxes () {
      this.$store.dispatch('infobox/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('infobox/setPage', page)
      this.getInfoboxes()
    },
    close () {
      this.$store.commit('infobox/resetFilter')
      this.$emit('close')
    },
    insert () {
      if (this.selectedInfobox) {
        this.$emit('set-data', this.selectedInfobox)
        this.$emit('close')
      }
    },
    showInfoboxCreateModal () {
      this.$emit('show-infobox-create-modal')
    },
    showInfoboxListModal () {
      this.$emit('show-infobox-list-modal')
    },
    selectInfobox (infoboxes) {
      this.selectedInfobox = infoboxes[0]
    }
  },
  mounted () {
    this.page = this.$store.getters['infobox/page']
    this.getInfoboxes()
  }
}
</script>
