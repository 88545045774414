<template>
  <div class="modal-html-id-attr">
    <form
      v-click-outside="modalHandler"
      class="modal-html-id-attr__dialog-wr"
      @submit.prevent="setHtmlId"
    >
      <h4
        :class="['modal-html-id-attr__title', { 'modal-html-id-attr__title--shaking': shaking }]"
        v-html="$t('nmhEditor.modals.htmlIdAttr.titleRest')"
      >
      </h4>
      <div class="modal-html-id-attr__content-area">
        <div v-if="existingLink" class="alert alert-warning">
          <i class="fa fa-exclamation-triangle" />
          {{ $t('nmhEditor.modals.htmlIdAttr.existingLinkWarning', { text: existingLink.text, htmlId: existingLink.htmlId }) }}
        </div>
        <button
          class="modal-html-id-attr__btn modal-html-id-attr__btn--primary"
          @click.prevent="generateUniqueHtmlId"
          type="button"
        >
          {{ $t('nmhEditor.modals.htmlIdAttr.generateUniqueId') }}
        </button>
        <label class="modal-html-id-attr__label" for="modal-html-id-attr__input">
          <span class="modal-html-id-attr__desc">ID: </span>
          <input
            id="modal-html-id-attr__input"
            ref="modalHtmlIdAttr"
            class="modal-html-id-attr__input"
            type="text"
            v-model="inputData"
            placeholder="some-identifier"
          >
          <i class="fa fa-info-circle" :title="$t('nmhEditor.modals.htmlIdAttr.info')"></i>
        </label>
        <div class="modal-html-id-attr__input__error-message">
          &nbsp;
          {{ isDuplicate ? $t('nmhEditor.modals.htmlIdAttr.duplicateId') : '' }}
        </div>
      </div>
      <div class="modal-html-id-attr__btn-area">
        <button
          class="modal-html-id-attr__btn modal-html-id-attr__btn--primary"
          @click.prevent="setHtmlId"
          type="button"
          :disabled="isDuplicate"
        >
          {{ $t('nmhEditor.modals.link.btn.confirm') }}
        </button>
        <button
          class="modal-html-id-attr__btn modal-html-id-attr__btn--secondary"
          @click.prevent="modalHandler" type="button"
        >
          {{ $t('nmhEditor.modals.link.btn.cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalHtmlIdAttr',
  data () {
    return {
      inputData: '',
      existingLink: null,
      shaking: false
    }
  },
  computed: {
    ...mapState({
      blocks: state => state.nmhEditorStore.data.blocks,
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock
    }),
    isDuplicate () {
      return this.blocks.some((b, index) => index !== this.lastClickedBlock.index && b.properties.htmlId === this.inputData)
    }
  },
  methods: {
    isIdValid (id) {
      const re = /^[A-Za-z]+[\w\-:.]*$/
      return re.test(id)
    },
    modalHandler () {
      this.$store.commit('nmhEditorStore/SET_MODAL_HTML_ID_ATTR', false)
    },
    async generateUniqueHtmlId () {
      this.inputData = await this.$store.dispatch('nmhEditorStore/generateUniqueHtmlId', this.lastClickedBlock)
    },
    setHtmlId () {
      if (this.isDuplicate) {
        return
      }
      if (this.inputData === '' || this.isIdValid(this.inputData)) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.lastClickedBlock.index,
          property: 'htmlId',
          data: this.inputData
        })
        this.modalHandler()
        return
      }
      this.shaking = true
      setTimeout(() => {
        this.shaking = false
      }, 500)
    },
    checkExistingLink (htmlId) {
      const link = `href="#${htmlId}`
      const existingLink = this.blocks.find(block => (block.properties.text ?? '').includes(link))
      if (existingLink) {
        const regex = new RegExp(`${link}.*?>(.*?)<`)
        const text = existingLink.properties.text.match(regex)?.[1]
        this.existingLink = { htmlId: existingLink.properties.htmlId, text }
      }
    }
  },
  mounted () {
    this.$refs.modalHtmlIdAttr.focus()
    const htmlId = this.blocks[this.lastClickedBlock.index].properties?.htmlId
    if (htmlId) {
      this.inputData = htmlId
      this.checkExistingLink(htmlId)
    }
  }
}
</script>

<style scoped lang="scss">
.modal-html-id-attr {
  @include fixed(0, 100);
  @include size(100% 100vh);
  @include padding(_ 10px);
  overflow-y: scroll;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &__dialog-wr {
    @include size(450px _);
    @include padding(30px 50px);
    @include radius(6px);
    background: #f2f3f7;
    box-shadow: 0 0 30px 5px #8d8d8d;
  }
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  &__title {
    @include font(400 18px "Roboto");
    @include margin(0);
    color: #465674;
    &--shaking {
      /deep/ > a {
        colro: #6599fe;
        display: inline-block;
        animation: shake 0.5s;
        animation-iteration-count: infinite;
      }
    }
  }
  &__content-area {
    @include margin(25px _ 15px);
    @include padding(20px _ _);
    @include grid-gap(15px);
    display: grid;
    border-top: 1px solid #ddd;
  }
  &__label {
    display: grid;
    grid-template-columns: 2.5rem auto min-content;
    align-items: center;
    grid-gap: .5rem;
  }
  &__desc {
    @include font(400 16px "Roboto");
    @include margin(0);
    color: #465674;
  }
  &__select,
  &__input {
    @include font(400 14px "Roboto");
    @include radius(4px);
    padding: 0.3125rem .4rem;
    border: 1px solid #d1dbe4;
    &::placeholder {
      opacity: .5;
    }
    &__error-message {
      @include font(400 13px "Roboto");
      margin-top: -0.5rem;
      padding-right: 1.4rem;
      color: #FF3455;
      text-align: right;
    }
  }
  &__btn-area {
    @include grid-gap(8px);
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: flex-end;
  }
  &__btn {
    @include font(400 16px "Roboto");
    @include radius(3px);
    @include padding(8px 15px);
    border: none;
    cursor: pointer;
    transition: 100ms all;
    &--primary {
      background: #6599fe;
      color: #fff;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &--secondary {
      border: 1px solid #000;
      color: #000;
      &:hover {
        opacity: .6;
      }
    }
  }
}
</style>
