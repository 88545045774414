<template>
  <app-modal
    :title="$t('article.modal.poll_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row m-b-10">
        <div class="col-lg-12 text-right">
          <button class="btn btn-secondary" @click="showPollCreateModal">{{ $t('article.modal.poll_create') }}</button>
          <button class="btn btn-secondary active" @click="showPollListModal">
            {{ $t('article.modal.poll_select') }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <app-poll-filter
            hide-type
          >
          </app-poll-filter>
          <div class="card">
            <div class="card-body">
              <app-preloader v-if="callingAPI"></app-preloader>
              <app-data-table
                :data="polls"
                :totalCount="totalCount"
                :page="page"
                :config="dataTableConfig"
                selectable
                disableMultipleSelect
                @page-change="setPageAndGetRecords"
                @select-record="selectPoll"
                actionsInBlankWindow
              >
              </app-data-table>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Sub header -->
    <template slot="sub-header">
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="insert" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import Preloader from '@/components/preloader/Preloader'
import PollFilter from '@/components/poll/PollFilter'
import DataTable from '@/components/shared/DataTable'
import Modal from '@/components/shared/Modal'
import { CATEGORY_MAP } from '@/model/ValueObject/PollCategory'

export default {
  name: 'PollListModal',
  data () {
    return {
      dataLoaded: false,
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('poll.list.id'),
          title: this.$t('poll.list.title'),
          hidden: this.$t('poll.list.hidden'),
          enabledVoting: this.$t('poll.list.enabled_voting'),
          createdAt: this.$t('poll.list.created_at'),
          modifiedAt: this.$t('poll.list.modified_at'),
          createdBy: this.$t('poll.list.created_by')
        },
        actions: {
          detail: 'poll_detail',
          edit: 'poll_edit'
        },
        render: {
          hidden: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          enabledVoting: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          category: function (data) {
            return CATEGORY_MAP[data]
          }
        }
      },
      selectedPoll: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    polls () {
      return this.$store.getters['poll/list']
    },
    totalCount () {
      return this.$store.getters['poll/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['poll/totalCount'] / config.defaults.list.limit)
    },
    articleDetail () {
      return this.$store.getters['article/detail']
    }
  },
  components: {
    appModal: Modal,
    appPreloader: Preloader,
    appPollFilter: PollFilter,
    appDataTable: DataTable
  },
  methods: {
    getPolls () {
      this.$store.dispatch('poll/fetchPolls')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('poll/setPage', page)
      this.getPolls()
    },
    close () {
      this.$store.commit('poll/resetFilter')
      this.$store.commit('poll/resetArticleUuid')
      this.$emit('close')
    },
    insert () {
      this.$emit('set-data', this.selectedPoll)
      this.close()
    },
    showPollCreateModal () {
      this.$emit('show-poll-create-modal')
    },
    showPollListModal () {
      this.$emit('show-poll-list-modal')
    },
    selectPoll (polls) {
      this.selectedPoll = polls[0]
    }
  },
  mounted () {
    this.page = this.$store.getters['poll/page']
    this.getPolls()
    this.$store.commit('poll/setArticleUuid', this.articleDetail?.documentId)
  }
}
</script>
