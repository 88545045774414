import axios from 'axios'
import appConfig from '../config'

const linkPreviewApi = () => axios.create({
  baseURL: appConfig.linkPreview.apiUrl(),
  timeout: 15 * 1000,
  headers: {
    Authorization: `Bearer ${appConfig.linkPreview.apiToken()}`,
    'Content-Type': 'application/json'
  }
})

export default linkPreviewApi
