<template>
  <button class="button-main" @click="clickHandler">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonMain',
  props: {
    clickHandler: {
      type: Function,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
  .button-main {
    @include font(500 13px "Roboto");
    @include margin(_ auto);
    @include padding(8px 14px);
    @include radius(6px);
    cursor: pointer;
    display: block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
</style>
