import { render, staticRenderFns } from "./GenerateHtmlFromPrArticle.vue?vue&type=template&id=6628f9f4&scoped=true&class=generate-html-from-pr-article&"
import script from "./GenerateHtmlFromPrArticle.vue?vue&type=script&lang=js&"
export * from "./GenerateHtmlFromPrArticle.vue?vue&type=script&lang=js&"
import style0 from "./GenerateHtmlFromPrArticle.vue?vue&type=style&index=0&id=6628f9f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6628f9f4",
  null
  
)

export default component.exports