<template>
  <div v-if="!disabled" class="floating-bar">
    <EditorItemsMenu :horizontal="true" :click-handler="clickHandler" />
  </div>
</template>

<script>
import EditorItemsMenu from '@/components/nmhEditor/EditorItemsMenu'
import { mapState } from 'vuex'
export default {
  name: 'FloatingBar',
  components: {
    EditorItemsMenu
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled,
      currentSiteName: state => state.article.currentSiteName
    })
  },
  methods: {
    clickHandler (e) {
      const blocksModel = JSON.parse(JSON.stringify(this.blocksModel))
      const selected = blocksModel.blocks.filter(item => item.type === e.target.dataset.type)[0]
      if (selected === undefined || selected === null) {
        return
      }
      this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', 'atTheEnd')
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        setNewBlock: selected
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .floating-bar {
    @include margin(_ _ 40px);
    position: sticky;
    top: 61px;
    z-index: 3;
    background: #fff;
    border-top: 1px solid #fff;
  }
</style>
