<template>
  <div class="modal-select-block">
    <div v-click-outside="modalHandler" class="modal-select-block__dialog-wr">
      <div class="modal-select-block__header">
        <SvgIconSelectBlock class="modal-select-block__svg-select-block" />
        <h4 class="modal-select-block__title">{{ $t('nmhEditor.modals.selectBlock.title') }}</h4>
        <SvgIconTimes class="modal-select-block__times" @click="modalHandler" />
      </div>
      <EditorItemsMenu :vertical="true" :click-handler="clickHandler" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EditorItemsMenu from '@/components/nmhEditor/EditorItemsMenu'
import SvgIconTimes from '@/assets/img/svg/times.svg?inline'
import SvgIconSelectBlock from '@/assets/img/svg/select-block.svg?inline'
export default {
  name: 'ModalSelectBlock',
  components: {
    EditorItemsMenu,
    SvgIconTimes,
    SvgIconSelectBlock
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      selectBlockModalReplaceMode: state => state.nmhEditorStore.modals.selectBlock.replaceMode
    })
  },
  methods: {
    clickHandler (e) {
      const blocksModel = JSON.parse(JSON.stringify(this.blocksModel))
      const selected = blocksModel.blocks.filter(item => item.type === e.target.dataset.type)[0]
      if (selected === undefined || selected === null) {
        this.modalHandler()
        return
      }
      if (this.selectBlockModalReplaceMode) {
        this.$store.commit('nmhEditorStore/SET_BLOCKS', {
          changeCurrentBlockType: selected
        })
      } else {
        this.$store.commit('nmhEditorStore/SET_BLOCKS', {
          setNewBlock: selected
        })
      }
      this.modalHandler()
    },
    modalHandler () {
      this.$store.commit('nmhEditorStore/SET_MODAL_SELECT_BLOCK', false)
      this.$store.commit('nmhEditorStore/SET_MODAL_SELECT_BLOCK_REPLACE_MODE', false)
    }
  },
  mounted () {
    document.body.style.overflowY = 'hidden'
  },
  destroyed () {
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style lang="scss" scoped>
  .modal-select-block {
    @include fixed(0, 100);
    @include size(100% 100vh);
    @include padding(_ 10px);
    overflow-y: scroll;
    background: rgba(0,0,0,.7);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &__dialog-wr {
      @include size(760px _);
      @include margin(40px _);
      @include radius(6px);
      overflow: hidden;
      background: #f2f3f7;
    }
    &__header {
      @include size(_ 60px);
      @include padding(_ 30px);
      @include grid-gap(8px);
      background: #fff;
      display: grid;
      align-items: center;
      grid-template-columns: min-content auto min-content;
    }
    &__times {
      cursor: pointer;
      transform: scale(1);
      transition: all 200ms;
      &:hover {
        transform: scale(1.2);
      }
    }
    &__title {
      @include font(500 18px "Roboto");
      @include margin(0);
      color: #465674;
    }
  }
</style>
