<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="embedIsNotEmpty"
    :click-handler-for-edit-btn="showModal"
  >
    <button v-if="!embedIsNotEmpty" class="third-party-embed__btn-empty" @click="showModal">
      {{ $t('nmhEditor.blocks.thirdPartyEmbedBlock.empty') }}
    </button>

    <div v-if="embedIsNotEmpty" class="third-party-embed__not-empty-wrapper">
      <!--   PINTEREST PREVIEW  -->
      <LinkPreview
        v-if="isPinterest"
        class="third-party-embed__link-preview"
        :url="properties.originalContent"
        media-type-requirement="pinterestapp:pin"
      />
      <!--   Facebook/Twitter/Instagram preview   -->
      <div class="third-party-embed__widget-preview" v-else v-html="widgetPreview" />
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import LinkPreview from '@/components/LinkPreview'

/**
 * Important:
 * This component works only if there are javascript files imported in 'public/index.html'
 *  e.g.:
 *   <script async src="https://platform.x.com/widgets.js" charset="utf-8" />
 *   <script async src="https://www.instagram.com/embed.js" />
 *   etc.
 */
export default {
  name: 'ThirdPartyEmbedBlock',
  components: {
    Block,
    LinkPreview
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      theModalIsVisible: false,
      widgetPreview: '',
      podcast: {
        id: 'podcast',
        searchedWords: ['www.podbean.com']
      }
    }
  },
  computed: {
    isPinterest () {
      return this.properties.type === 'pinterest'
    },
    embedIsNotEmpty () {
      return this.properties.type.length && this.properties.originalContent.length
    }
  },
  methods: {
    /**
     * Parse id from URL in originalContent. This ID always follows the words stored in searchedWords.
     * Then the text after the first dash is saved to vuex store to the property embedId.
     * @param {Array} searchedWords - Words to search in the url
     * @param {String} originalContent - Original content of the embed
     */
    podcastIdParser (searchedWords, originalContent) {
      const urlSegments = originalContent.split('/')
      const searchedWord = searchedWords.find(word => urlSegments.includes(word))
      const index = urlSegments.indexOf(searchedWord)
      if (index !== -1) {
        const fullIdSegments = urlSegments[index + 2].split('-') // skipping '/eas/' or '/ew/' or any other
        fullIdSegments.shift()
        const idSegment = fullIdSegments.join('-')
        if (idSegment) {
          this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
            index: this.index,
            property: 'embedId',
            data: idSegment
          })
        }
      }
    },
    loadWidgetPreview () {
      const getWidgetPreview = () => {
        switch (this.properties.type) {
          case 'instagram':
            return this.getInstagramWidget()
          case 'twitter':
            return this.getTwitterWidget()
          case 'facebook':
            return this.getFacebookWidget()
          case 'podcast':
            return this.getPodcastWidget()
        }
        throw Error('Unsupported type')
      }

      if (this.embedIsNotEmpty && !this.isPinterest) {
        try {
          this.widgetPreview = getWidgetPreview()
        } catch (error) {
          console.error(`unable to load ${this.properties.type} widget script`)
          console.error(error)
        }
      }
    },
    getInstagramWidget () {
      const instagramPreview = `
        <blockquote
          class="instagram-media"
          data-instgrm-captioned
          data-instgrm-permalink="${this.properties.originalContent}"
          data-instgrm-version="14"
        >
         <a href="${this.properties.originalContent}">${this.properties.originalContent}</a>
        </blockquote>
      `
      this.$nextTick(() => {
        window.instgrm.Embeds.process()
      })
      return instagramPreview
    },
    getTwitterWidget () {
      const twitterPreview = `
        <blockquote class="twitter-tweet">
          <a href="${this.properties.originalContent}">
            ${this.properties.originalContent}
          </a>
        </blockquote>
      `
      this.$nextTick(() => {
        window.twttr.widgets.load()
      })
      return twitterPreview
    },
    getFacebookWidget () {
      const facebookPreview = `
        <div
          class="fb-post"
          data-href="${this.properties.originalContent}"
        />
      `
      this.$nextTick(() => {
        window.FB.XFBML.parse()
      })
      return facebookPreview
    },
    getPodcastWidget () {
      return `
        <i class="third-party-embed__podcast-icon fa fa-podcast" aria-hidden="true"></i>
        <div">
          <strong>Podcast: </strong>
          <a target="_blank" href="${this.properties.originalContent}">${this.properties.originalContent}</a>
        </div>
      `
    },
    showModal () {
      this.$store.commit('nmhEditorStore/SET_MODAL_THIRD_PARTY_EMBED', {
        isVisible: true,
        infographic: false
      })
    }
  },
  mounted () {
    this.loadWidgetPreview()
  },
  watch: {
    properties: {
      deep: true,
      handler () {
        this.loadWidgetPreview()
        this.podcastIdParser(this.podcast.searchedWords, this.properties.originalContent)
      }
    }
  }
}
</script>

<style lang="scss">
.third-party-embed {
  &__btn-empty {
    @include font(500 13px "Roboto");
    @include margin(_ auto);
    @include padding(8px 14px);
    @include radius(6px);
    cursor: pointer;
    display: block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__text {
    @include font(400 15px "Roboto");
    @include relative(_);
    @include padding(_ _ _ 15px);
    display: flex;
    align-items: center;
    &::before {
      @include absolute(5px _ _ 0);
      @include size(8px);
      @include radius(50%);
      background: #465674;
      content: '';
    }
  }
  &__text-strong {
    @include font(500 16px "Roboto");
    color: #6599fe;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }
  &__not-empty-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    background: rgba(209, 219, 228, 0.6);
    padding: 1rem;
    border-radius: .3rem;
  }
  &__text-not-empty {
    @include font(400 18px "Roboto");
    @include margin(0);
    @include relative(-1px _ _);
    color: #465674;
    word-break: break-word;
  }
  &__podcast-icon {
    color: #6599fe;
    font-size: 1.5rem;
    margin-right: .7rem;
  }
  &__widget-preview {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
.twitter-tweet,
.instagram-media,
.fb-post {
  width: 500px !important;
  margin: 0 !important;
  max-width: 500px !important;
  min-width: auto !important;
}

.fb-post {
  background: #fff !important;
  @include bp(0 6) {
    width: 100% !important;
  }
  iframe,
  span {
    width: 100% !important;
  }
}

.twitter-tweet,
.twitter-tweet iframe,
.instagram-media {
  @include bp(0 6) {
    width: 100% !important;
  }
}
</style>
