<template>
  <app-modal
    :title="$t('article.modal.quiz_header')"
    @close="close"
  >
    <!-- Header -->
    <template v-slot:header>
      <button
        v-if="quizzes.length"
        @click="addQuiz"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="quiz_btn_select"
        :disabled="selectedQuiz === null"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>

    <!-- Body -->
    <template v-slot:body>
      <app-quiz-filter class="m-b-10" disable-status-filter></app-quiz-filter>
      <OutlinedCheckbox
        v-if="outlinedCheckbox"
        class="outlined-checkbox-quiz-modal"
        v-model="rewriteArticleOption"
        :label="$tc('quiz.rewriteArticle')"
      />
      <app-preloader v-if="callingAPI"></app-preloader>
      <app-data-table
        :data="quizzes"
        :totalCount="totalCount"
        :page="page"
        :config="dataTableConfig"
        selectable
        actions-in-blank-window
        :disable-multiple-select=!multiple
        @page-change="setPageAndGetRecords"
        @select-record="selectQuiz"
      >
      </app-data-table>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import Config from '../../config'
import Preloader from '../preloader/Preloader'
import QuizFilter from '../filter/FilterQuiz'
import DataTable from '../shared/DataTable'
import Modal from '../shared/Modal'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'

export default {
  name: 'QuizModal',
  components: {
    appModal: Modal,
    appQuizFilter: QuizFilter,
    appDataTable: DataTable,
    appPreloader: Preloader,
    OutlinedCheckbox
  },
  props: {
    outlinedCheckbox: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rewriteArticleOption: false,
      page: 1,
      search: {
        id: '',
        title: ''
      },
      dataTableConfig: {
        fields: {
          id: 'Id',
          title: 'Title',
          type: 'Type',
          hidden: 'Hidden',
          createdAt: 'Created at',
          createdBy: 'Created by'
        },
        actions: {
          detail: 'quiz_detail',
          edit: 'quiz_edit'
        },
        render: {
          hidden: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          }
        }
      },
      selectedQuiz: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    quizzes () {
      return this.$store.getters['quiz/list']
    },
    totalCount () {
      return this.$store.getters['quiz/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['quiz/totalCount'] / Config.defaults.limit)
    }
  },
  methods: {
    getQuizzes () {
      this.$store.dispatch('quiz/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('quiz/setPage', this.page)
      this.getQuizzes()
    },
    selectQuiz (quiz) {
      if (this.multiple === false) {
        this.selectedQuiz = null
      }
      this.selectedQuiz = quiz[0]
    },
    addQuiz () {
      this.$emit('set-data', {
        selectedData: this.selectedQuiz,
        rewriteArticle: this.rewriteArticleOption
      })
      this.$emit('close')
    },
    resetFilters () {
      const filter = {
        id: '',
        title: ''
      }
      this.$store.commit('quiz/setPage', 1)
      this.$store.commit('quiz/setFilter', filter)
      this.$store.dispatch('quiz/fetch')
    },
    close () {
      this.$emit('close')
    }
  },
  beforeMount () {
    this.resetFilters()
  }
}
</script>

<style lang="scss">
  .outlined-checkbox-quiz-modal {
    width: fit-content;
    padding-right: 1.5rem;
    margin-left: auto;
    margin-bottom: 1rem;
  }
</style>
