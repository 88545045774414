<template>
  <app-modal
    :title="$t('article.modal.poll_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row m-b-10">
        <div class="col-lg-12 text-right">
          <button class="btn btn-secondary active" @click="showPollCreateModal">
            {{ $t('article.modal.poll_create') }}
          </button>
          <button class="btn btn-secondary" @click="showPollListModal">{{ $t('article.modal.poll_select') }}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <input type="hidden" v-model="poll.id">
          <app-input
            v-model="poll.title"
            @blur="$v.poll.title.$touch()"
            :error="$v.poll.title.$error"
            id="poll_title"
            :label="$t('article.modal.poll_question')"
          >
          </app-input>
          <app-select
            v-model="poll.type"
            :options="typesFiltered"
            id="poll_types"
            :label="$t('poll.type')"
            no-empty-value
          >
          </app-select>
          <label>{{ $t('article.modal.poll_options') }}</label>
          <div class="card card-outline-success" v-for="(option, index) in poll.options" :key="`item-${index}`">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-10">
                  <h4 class="m-b-0 text-white">{{ $t('article.modal.poll_option') }} {{ index + 1 }}</h4>
                </div>
                <div class="col-lg-2 text-right">
                  <button
                    class="btn btn-danger btn-sm text-right"
                    title="Zmazať odpoveď"
                    @click="removeOption(index)"
                  >
                    <i class="fas fa-window-close"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="option.title"
                    id="option_title"
                    :label="$t('article.modal.poll_option_title')"
                    disable-form-group
                  >
                  </app-input>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-info m-t-10"
            :title="$t('article.modal.poll_add_option')"
            @click="addOption"
          >
            <i class="fa fa-plus"></i> {{ $t('article.modal.poll_add_option') }}
          </button>
        </div>
        <div class="col-lg-6">
          <app-select
            v-if="displayTypes"
            v-model="poll.displayType"
            :options="displayTypes"
            id="poll_displayType"
            :label="$t('article.modal.poll_display_type')"
            no-empty-value
          >
          </app-select>
          <app-select
            v-if="restrictionTypes && poll.type !== 'results'"
            v-model="poll.restrictionType"
            :options="restrictionTypes"
            id="poll_restrictionType"
            :label="$t('article.modal.poll_restriction_type')"
            no-empty-value
          >
          </app-select>
          <div class="row">
            <div class="col-lg-6">
              <app-datepicker
                v-if="poll.type !== 'results'"
                v-model="poll.endOfVoting"
                id="poll_endOfVoting"
                :label="$t('article.modal.poll_end_of_voting')">
              </app-datepicker>
            </div>
            <div class="col-lg-6">

            </div>
            <div class="col-lg-6">
              <app-datepicker
                v-model="poll.orderDate"
                id="poll_orderDate"
                :label="$t('article.modal.poll_order_date')">
              </app-datepicker>
            </div>
            <div class="col-lg-6">
              <app-datepicker
                v-model="poll.publishedUntil"
                id="poll_publishedUntil"
                :label="$t('article.modal.poll_published_until')">
              </app-datepicker>
            </div>
            <div class="col-lg-6">
              <app-checkbox
                v-model="showPublishedSince"
                id="poll_showPublishedSince"
                :label="$t('article.modal.poll_show_published_since')">
              </app-checkbox>
              <app-datepicker
                v-if="showPublishedSince"
                v-model="poll.publishedSince"
                id="poll_publishedSince"
                :label="$t('article.modal.poll_published_since')">
              </app-datepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-checkbox
                v-model="poll.hidden"
                id="poll_hidden"
                :label="$t('article.modal.poll_hidden')"
              >
              </app-checkbox>
            </div>
            <div class="col-lg-12">
              <app-checkbox
                v-if="poll.type !== 'results'"
                v-model="poll.enabledVoting"
                id="poll_enabledVoting"
                :label="$t('article.modal.poll_enabled_voting')"
              >
              </app-checkbox>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Sub header -->
    <template slot="sub-header">
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="create" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import PollModel from '@/model/PollModel'
import NotifyService from '@/services/NotifyService'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Input from '../../form/inputs/Input'
import Datepicker from '../../form/Datepicker'
import Select from '../../form/select/Select'
import Checkbox from '../../form/Checkbox'
import Modal from '../../shared/Modal'
import PollTypeMixin, { POLL_TYPE_LIKE_DISLIKE } from '../../mixins/valueObject/PollTypeMixin'
import { AVAILABLE_VALUES as AVAILABLE_CATEGORIES } from '../../../model/ValueObject/PollCategory'
import { AVAILABLE_VALUES as AVAILABLE_DISPLAY_TYPES } from '../../../model/ValueObject/PollDisplayType'
import { AVAILABLE_VALUES as AVAILABLE_RESTRICTION_TYPES } from '../../../model/ValueObject/PollRestrictionType'

export default {
  name: 'PollCreateModal',
  mixins: [PollTypeMixin],
  props: {
    selectedData: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      poll: this._.cloneDeep(PollModel),
      dataLoaded: false,
      isLoading: false,
      showPublishedSince: false
    }
  },
  validations: {
    poll: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      }
    }
  },
  computed: {
    typesFiltered () {
      return this.pollTypeValues.filter(item => item.id !== POLL_TYPE_LIKE_DISLIKE)
    },
    categories () {
      return AVAILABLE_CATEGORIES
    },
    displayTypes () {
      return AVAILABLE_DISPLAY_TYPES
    },
    restrictionTypes () {
      return AVAILABLE_RESTRICTION_TYPES
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appSelect: Select,
    appCheckbox: Checkbox,
    appDatepicker: Datepicker
  },
  methods: {
    close () {
      this.clearData()
      this.$emit('close')
    },
    clearData () {
      this.value = ''
    },
    showPollCreateModal () {
      this.$emit('show-poll-create-modal')
    },
    showPollListModal () {
      this.$emit('show-poll-list-modal')
    },
    addOption () {
      const result = {
        title: ''
      }
      this.poll.options.push(result)
    },
    removeOption (index) {
      this.poll.options.splice(index, 1)
    },
    preparePollRequest (poll) {
      if (poll.publishedUntil === '') {
        delete poll.publishedUntil
      }
      if (poll.type === 'results') {
        poll.enabledVoting = false
      }
      return poll
    },
    async create () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('poll/create', this.preparePollRequest(this.poll))
        .then(() => {
          this.poll = this.$store.getters['poll/detail']
          if (this.$store.getters['poll/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('article.notify.poll_created'))
            this.$emit('set-data', this.$store.getters['poll/detail'])
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['poll/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.poll.type = 'poll'
    this.addOption()
    this.addOption()
  }
}
</script>

<style>
</style>
