<template>
  <div v-if="!disabled" class="placeholder-block" @click="clickHandler">
    <h3 class="placeholder-block">
      {{ placeholder }}
    </h3>
    <BtnAddBlock :at-the-end="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BtnAddBlock from '@/components/nmhEditor/btns/BtnAddBlock'
export default {
  name: 'PlaceholderBlock',
  props: {
    placeholder: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    BtnAddBlock
  },
  computed: {
    ...mapState({
      blocksModel: state => state.nmhEditorStore.blocksModel,
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled
    })
  },
  methods: {
    clickHandler () {
      const blocksModel = JSON.parse(JSON.stringify(this.blocksModel))
      const paragraphBlock = blocksModel.blocks.filter(item => item.type === 'paragraphBlock')[0]
      this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', 'atTheEnd')
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        setNewBlock: paragraphBlock
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .placeholder-block {
    @include font(400 16px 26px "Roboto");
    @include margin(0);
    @include grid-gap(10px);
    color: #8a96ac;
    font-style: italic;
    display: grid;
    grid-template-columns: auto min-content;
  }
</style>
