<template>
  <div>
    <div v-if="dataLoaded">
      <p v-if="history.length === 0" class="alert alert-info">{{ $t('article.article_history_not_found') }}</p>
    </div>
    <table class="table table-striped table-bordered" v-if="dataLoaded && history.length > 0">
      <tbody>
      <tr>
        <th>{{ $t('article.time') }}</th>
        <th>{{ $t('article.type') }}</th>
        <th>{{ $t('article.user') }}</th>
      </tr>
      <tr v-for="(record, index) in history" :key="`item-${index}`">
        <td>{{ record.changedAt | prettyDateTime }}</td>
        <td>{{ getChangeTypeName(record.changeType) }}</td>
        <td>{{ getUserInfo(record.changedBy) }}</td>
      </tr>
      </tbody>
    </table>
    <app-preloaded v-if="!dataLoaded"></app-preloaded>
  </div>
</template>

<script>
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'
import Preloader from '../preloader/Preloader'
import UserMixin from '../mixins/User'

export default {
  name: 'ArticleHistory',
  mixins: [UserMixin],
  props: {
    article: {
      type: Object
    }
  },
  data () {
    return {
      dataLoaded: false,
      history: []
    }
  },
  components: {
    appPreloaded: Preloader
  },
  methods: {
    getHistory () {
      this.dataLoaded = false
      CoreApi().get('/article/history?order[id]=desc&filter_eq[articleDocId]=' + this.article.documentId)
        .then(result => {
          this.history = result.data.data
          this.dataLoaded = true
        })
        .catch(error => {
          console.log(error)
          NotifyService.setErrorMessage('Can not to retrieve history of article.')
          this.dataLoaded = true
        })
    },
    getChangeTypeName (changeType) {
      return this.$t('article.status_' + changeType)
    }
  },
  created () {
    this.getHistory()
  }
}
</script>
