<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="!blockPropertiesAreEmpty"
    :click-handler-for-edit-btn="showModal"
  >
    <button
      v-if="blockPropertiesAreEmpty"
      class="infographic-block__btn-empty"
      @click="showModal"
    >
      {{ $t('nmhEditor.blocks.infographicBlock.empty') }}
    </button>

    <div v-if="!blockPropertiesAreEmpty" class="infographic-block__not-empty-wrapper">
      <img
        class="infographic-block__icon"
        :src="require(`@/assets/img/svg/${properties.type}.svg`)"
        :alt="properties.type"
      >
      <div class="infographic-block__text">
        <span class="infographic-block__title">
          {{ properties.type }}
          {{ $t('nmhEditor.blocks.infographicBlock.title') }}:
        </span>
        <a
          class="infographic-block__link"
          :title="properties.originalContent"
          :href="properties.originalContent"
          target="_blank"
        >
          {{ properties.originalContent }}
        </a>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import { isURL } from '@/filters'

export default {
  name: 'InfographicBlock',
  components: {
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      flourish: {
        id: 'flourish',
        searchedWords: ['visualisation', 'story']
      },
      tableau: {
        id: 'tableau'
      },
      infogram: {
        id: 'infogram',
        searchedWords: ['infogram.com']
      }
    }
  },
  computed: {
    /**
     * Returns true if the block properties are empty
     */
    blockPropertiesAreEmpty () {
      return this.properties.type === '' &&
        this.properties.originalContent === '' &&
        this.properties.infographicId === ''
    }
  },
  methods: {
    /**
     * Shows the modal for editing the embed
     */
    showModal () {
      this.$store.commit('nmhEditorStore/SET_MODAL_THIRD_PARTY_EMBED', {
        isVisible: true,
        infographic: true
      })
    },
    /**
     * Parse and set infographic id property from the original content
     * @param {Array} searchedWords - Words to search in the url
     * @param {String} originalContent - Original content of the embed
     */
    infographicIdParser (searchedWords, originalContent) {
      const urlSegments = originalContent.split('/')
      const searchedWord = searchedWords.find(word => urlSegments.includes(word))
      const index = urlSegments.indexOf(searchedWord)
      if (index !== -1) {
        const idSegment = urlSegments[index + 1]
        if (idSegment) {
          this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
            index: this.index,
            property: 'infographicId',
            data: idSegment
          })
        }
      }
    },
    /**
     * Parse and set infographic id property
     */
    parseAndSetInfographicId () {
      // *** FLOURISH ***
      if (this.flourish.id === this.properties.type && isURL(this.properties.originalContent)) {
        this.infographicIdParser(this.flourish.searchedWords, this.properties.originalContent)
        return
      }

      // *** TABLEAU ***
      if (this.tableau.id === this.properties.type && isURL(this.properties.originalContent)) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'infographicId',
          data: this.properties.originalContent
        })
        return
      }

      // *** INFOGRAM ***
      if (this.infogram.id === this.properties.type && isURL(this.properties.originalContent)) {
        this.infographicIdParser(this.infogram.searchedWords, this.properties.originalContent)
        return
      }

      console.error('Infographic type not found')
    }
  },

  /**
   * Watch for changes in the properties
   */
  watch: {
    properties: {
      handler () {
        return this.parseAndSetInfographicId()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.infographic-block {
  &__btn-empty {
    @include font(500 13px "Roboto");
    @include margin(_ auto);
    @include padding(8px 14px);
    @include radius(6px);
    cursor: pointer;
    display: block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;

    &:hover {
      background: darken(#6599fe, 10%);
    }
  }

  &__not-empty-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(209, 219, 228, 0.6);
    padding: 1rem;
    border-radius: 0.3rem;
  }

  &__title {
    white-space: nowrap;
    padding: 0 0.5rem;
    text-transform: capitalize;
    @media (max-width: 567px) {
      white-space: initial;
    }
  }

  &__link {
    word-break: break-all;
    font-weight: 400;
  }

  &__icon {
    width: 20px;
    height: auto;
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(192deg);
  }

  &__text {
    @include font(bold 16px "Roboto");
    @include margin(0);
    color: #26272a;
    position: relative;
    top: 1px;
    text-transform: lowercase;;
    &:first-letter {
      text-transform: uppercase !important;
    }
    display: flex;
    align-items: center;
  }
}
</style>
