<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterTests">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <app-select
                v-model="filter.discriminator"
                :options="discriminators"
                no-empty-value
                id="filter_discriminators"
                :label="$t('articleTest.filter.discriminators')"
                required
              >
              </app-select>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.title" id="filter_title"
                         :label="$t('articleTest.filter.title')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('articleTest.filter.id')"></app-input>
            </div>
            <div class="col-lg-2">
              <label>&nbsp;</label>
              <div class="form-group">
                <button class="btn btn-success pull-right">
                  {{ $t('articleTest.filter.search') }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-test="reset_filter"
                  @click="resetFilter"
                >
                  {{ $t('buttons.reset_filter') }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import ArticleTestFilter from '../../model/ArticleTestFilterModel'

export default {
  name: 'ArticleTestFilter',
  props: {
    discriminators: {
      type: Array
    }
  },
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(ArticleTestFilter)
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    discriminatorReset () {
      this.filter.discriminator = this.discriminators[0].id
    },
    filterTests () {
      this.$store.commit('articleTest/setPage', 1)
      this.$store.commit('articleTest/setFilter', this.filter)
      this.$store.dispatch('articleTest/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(ArticleTestFilter)
      this.discriminatorReset()
      this.$store.commit('articleTest/setFilter', this.filter)
      this.$store.dispatch('articleTest/fetch')
    }
  },
  beforeMount () {
    this.discriminatorReset()
    this.filter = this.$store.getters['articleTest/filter']
  }
}
</script>

<style lang="scss">

</style>
