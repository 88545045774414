<template>
  <Modal
    :title="$t('article.modal.quiz_header')"
    @close="close"
  >
    <template v-slot:header>
      <button
        v-if="quizzes.length"
        @click="addQuiz"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="quiz_btn_select"
        :disabled="selectedQuiz === null"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>

    <template v-slot:body>
      <QuizFilter class="m-b-10" />
      <Preloader v-if="callingAPI" />
      <DataTable
        :data="quizzes"
        :totalCount="totalCount"
        :page="page"
        :config="dataTableConfig"
        selectable
        actions-in-blank-window
        disableMultipleSelect
        @page-change="setPageAndGetRecords"
        @select-record="selectQuiz"
      />
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import Config from '@/config'
import Preloader from '@/components/preloader/Preloader'
import QuizFilter from '@/components/quiz/vlm/QuizFilterVlm'
import DataTable from '@/components/shared/DataTable'
import Modal from '@/components/shared/Modal'

export default {
  name: 'QuizModalVlm',
  components: {
    Modal,
    QuizFilter,
    DataTable,
    Preloader
  },
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('quizVlm.list.id'),
          site: this.$t('quizVlm.list.server'),
          title: this.$t('quizVlm.list.title'),
          loggedInUsersOnly: this.$t('quizVlm.list.loggedInUsersOnly'),
          createdAt: this.$t('system.created_at'),
          modifiedAt: this.$t('system.modified_at'),
          createdBy: this.$t('system.created_by')
        },
        actions: {
          detail: 'quiz_detail',
          edit: 'quiz_edit'
        },
        render: {
          loggedInUsersOnly: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          site: siteId => {
            const site = this.$store.getters['site/siteById'](siteId)
            if (site) {
              return site.name
            }
            return siteId
          }
        }
      },
      selectedQuiz: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    quizzes () {
      return this.$store.getters['quizVlm/list']
    },
    totalCount () {
      return this.$store.getters['quizVlm/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['quizVlm/totalCount'] / Config.defaults.limit)
    }
  },
  methods: {
    getQuizzes () {
      this.$store.dispatch('quizVlm/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('quizVlm/setPage', this.page)
      this.getQuizzes()
    },
    selectQuiz (quiz) {
      this.selectedQuiz = quiz[0]
    },
    addQuiz () {
      this.$emit('set-data', {
        selectedData: this.selectedQuiz
      })
      this.$emit('close')
    },
    resetFilters () {
      const filter = {
        id: '',
        title: ''
      }
      this.$store.commit('quizVlm/setPage', 1)
      this.$store.commit('quizVlm/setFilter', filter)
      this.$store.dispatch('quizVlm/fetch')
    },
    close () {
      this.$emit('close')
    }
  },
  beforeMount () {
    this.resetFilters()
  }
}
</script>
