<template>
  <app-draggable tag="ul" :list="relatedDiseaseArticles" class="dragArea list-icons">
    <div v-for="(relatedDiseaseArticle, index) in relatedDiseaseArticles" :key="`item-${index}`" class="m-b-5">
      <button
        v-if="showRemoveButton"
        class="btn btn-xs btn-danger"
        @click="removeRelatedDiseaseArticle(relatedDiseaseArticle)"
      >
        <span class="fa fa-times"></span>
      </button>
      <span class="label related-article-title p-2">{{ relatedDiseaseArticle.field.title | truncate }}</span>
      <router-link
        tag="a"
        class="btn btn-default btn-sm"
        target="_blank"
        :title="relatedDiseaseArticle.documentId"
        :to="{ name: 'article_detail', params: { id: relatedDiseaseArticle.id }}"
      >
        <i class="fa fa-info"></i>
      </router-link>
      <app-article-status
        :article="relatedDiseaseArticle"
      >
      </app-article-status>
    </div>
  </app-draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import ArticleStatus from './ArticleStatus'

export default {
  name: 'RelatedDiseaseArticleList',
  props: {
    showRemoveButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    appDraggable: Draggable,
    appArticleStatus: ArticleStatus
  },
  computed: {
    relatedDiseaseArticles () {
      return this.$store.getters['article/relatedDiseaseArticles']
    }
  },
  methods: {
    removeRelatedDiseaseArticle (article) {
      this.$store.commit('article/removeRelatedDiseaseArticle', article)
    }
  }
}
</script>

<style lang="scss" scoped>
.related-article-title {
  font-size: 14px;
  cursor: move;
  background-color: #f6f6f6;
  color: #2f3d4a;
  width: 70%;
  border: 0;
}

.related-article-title:hover {
  background-color: #d6d6d6;
}
</style>
