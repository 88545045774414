<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
  >
    <div class="online-sport-match-block">
      <label class="online-sport-match-block__area online-sport-match-block__area--1">
      <span class="online-sport-match-block__desc">
        {{ $t('nmhEditor.blocks.onlineSportMatchBlock.matchId') }}
      </span>
        <input
          class="online-sport-match-block__input"
          type="number"
          min="1"
          v-model.trim="onlineSportMatchId"
        >
      </label>
      <label class="online-sport-match-block__area online-sport-match-block__area--2">
      <span class="online-sport-match-block__desc">
        {{ $t('nmhEditor.blocks.onlineSportMatchBlock.matchType') }}
      </span>
        <select class="online-sport-match-block__input" v-model="onlineSportMatchTab">
          <option
            v-for="category in categories"
            :key="category.name"
            :value="category.name"
          >
            {{ category.displayName }}
          </option>
        </select>
      </label>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
export default {
  name: 'OnlineSportMatchBlock',
  components: {
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      categories: [
        {
          name: 'other',
          displayName: this.$t('nmhEditor.blocks.onlineSportMatchBlock.categoryOther')
        },
        {
          name: 'hockey',
          displayName: this.$t('nmhEditor.blocks.onlineSportMatchBlock.categoryHockey')
        },
        {
          name: 'football',
          displayName: this.$t('nmhEditor.blocks.onlineSportMatchBlock.categoryFootball')
        },
        {
          name: 'tennis',
          displayName: this.$t('nmhEditor.blocks.onlineSportMatchBlock.categoryTennis')
        }
      ]
    }
  },
  computed: {
    onlineSportMatchId: {
      set (output) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'matchId',
          data: output
        })
      },
      get () {
        return this.properties.matchId
      }
    },
    onlineSportMatchTab: {
      set (output) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'matchType',
          data: output
        })
      },
      get () {
        return this.properties.matchType
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .online-sport-match-block {
    @include relative(_);
    @include grid-gap(20px);
    display: grid;
    &__desc {
      @include font(400 14px "Roboto");
      @include margin(_ _ 6px);
      color: #8a96ac;
    }
    &__input {
      @include font(400 14px "Roboto");
      @include radius(6px);
      @include size(100% 40px);
      @include padding(_ 15px);
      display: flex;
      align-items: center;
      border: solid 1px #d1dbe4;
      background: #fdfdfd;
      color: #465674;
    }
  }
</style>
