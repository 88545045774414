<template>
  <div class="abstract-modal">
    <form
      v-click-outside="close"
      class="abstract-modal__dialog-wr"
      @submit="confirm"
    >
      <h4 class="abstract-modal__title">{{ title }}</h4>
      <div class="abstract-modal__content-area">
        <slot name="modalContent" />
      </div>
      <div class="abstract-modal__btn-area">
        <button
          :class="`abstract-modal__btn abstract-modal__btn--primary${error ? '-disabled' : '' }`"
          :disabled="error"
          @click.prevent="confirm" type="button"
        >
          <span v-if="buttonsText === 'confirm-cancel'">
            {{ $t('nmhEditor.modals.btn.confirm') }}
          </span>
          <span v-else-if="buttonsText === 'yes-no'">
            {{ $t('yes') }}
          </span>
        </button>
        <button
          class="abstract-modal__btn abstract-modal__btn--secondary"
          @click.prevent="close" type="button"
        >
          <span v-if="buttonsText === 'confirm-cancel'">
            {{ $t('nmhEditor.modals.btn.cancel') }}
          </span>
          <span v-else-if="buttonsText === 'yes-no'">
            {{ $t('no') }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'AbstractModal',
  props: {
    title: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    buttonsText: {
      type: String,
      default: 'confirm-cancel' // 'confirm-cancel' | 'yes-no'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
  .abstract-modal {
    @include fixed(0, 100);
    @include size(100% 100vh);
    @include padding(_ 10px);
    overflow-y: scroll;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &__dialog-wr {
      @include size(450px _);
      @include padding(30px 50px);
      @include radius(6px);
      background: #f2f3f7;
      box-shadow: 0 0 30px 5px #8d8d8d;
    }
    &__title {
      @include font(400 18px "Roboto");
      @include margin(0);
      color: #465674;
    }
    &__content-area {
      @include margin(25px _ 15px);
      @include padding(20px _ _);
      @include grid-gap(8px);
      display: grid;
      border-top: 1px solid #ddd;
    }
    &__label {
      display: grid;
      grid-template-columns: 80px auto;
      align-items: center;
    }
    &__desc {
      @include font(400 16px "Roboto");
      @include margin(0);
      color: #465674;
    }
    &__select,
    &__input {
      @include font(400 14px "Roboto");
      @include radius(4px);
      @include padding(5px 10px);
      border: 1px solid #d1dbe4;
      &::placeholder {
        opacity: .5;
      }
      &__error-message {
        @include font(400 13px "Roboto");
        padding-top: 0.2rem;
        color: #FF3455;
        text-align: right;
      }
    }
    &__btn-area {
      @include grid-gap(8px);
      display: grid;
      grid-template-columns: max-content max-content;
      justify-content: flex-end;
    }
    &__btn {
      @include font(400 16px "Roboto");
      @include radius(3px);
      @include padding(8px 15px);
      border: none;
      cursor: pointer;
      transition: 100ms all;
      &--primary {
        background: #6599fe;
        color: #fff;
        &:hover {
          background: darken(#6599fe, 10%);
        }
      }
      &--primary-disabled {
        background-color: #D1DBE4;
        cursor: default;
        &:hover {
          background-color: #D1DBE4;
        }
      }
      &--secondary {
        border: 1px solid #000;
        color: #000;
        &:hover {
          opacity: .6;
        }
      }
    }
  }
</style>
