<template>
  <div>
    <div v-if="dataLoaded">
      <div v-if="info">
        {{ $t('article.antares_visits') }}: {{ info.pv }}
      </div>
      <p v-else class="alert alert-info">{{ $t('article.antares_info_not_available') }}</p>
    </div>
    <Preloader v-else />
  </div>
</template>

<script>
import AntaresApi from '../../api/antares'
import Preloader from '../preloader/Preloader'

export default {
  name: 'ArticleAntaresInfo',
  props: {
    article: {
      type: Object
    }
  },
  data () {
    return {
      dataLoaded: true,
      info: null
    }
  },
  components: {
    Preloader
  },
  methods: {
    getAntaresInfo () {
      this.dataLoaded = false
      AntaresApi().get(`/topArticles/getVisits?documentId=${this.article.documentId}&siteName=${this.article.expanded.site.name}`)
        .then(result => {
          this.info = result.data
          this.dataLoaded = true
        })
        .catch(error => {
          if (error.response.status !== 404) {
            console.error(error)
          }
          this.info = null
          this.dataLoaded = true
        })
    }
  },
  created () {
    if (process.env.NODE_ENV !== 'development') {
      // temporary commented - see CMS-2592
      // this.getAntaresInfo()
    }
  }
}
</script>
