<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
  >
    <div class="paid-content-break-block">
      <h4 class="paid-content-break-block__desc">{{ $t('nmhEditor.blocks.paidContentBreakBlock.desc') }}</h4>
      <div class="paid-content-break-block__vertical-line-left"></div>
      <div class="paid-content-break-block__horizontal-line"></div>
      <div class="paid-content-break-block__vertical-line-right"></div>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
export default {
  name: 'PaidContentBreakBlock',
  components: {
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .paid-content-break-block {
    display: flex;
    align-items: center;
    @include size(100% 16px);
    @include relative(_);
    &__desc {
      @include font(400 13px "Roboto");
      @include absolute(-10px _ _ 50%);
      color: #000;
      transform: translate(-50%, 0);
    }
    &__vertical-line-left {
      @include absolute(0 _ _ 0);
      @include size(2px 100%);
      background: #000;
      content: '';
    }
    &__horizontal-line {
      @include absolute(7px _ _ 0);
      @include size(100% 2px);
      background: #000;
      content: '';
    }
    &__vertical-line-right {
      @include absolute(0 0 _ _);
      @include size(2px 100%);
      background: #000;
      content: '';
    }
  }
</style>
