<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="carTableHasId"
    :click-handler-for-edit-btn="showModal"
  >
    <button v-if="!carTableHasId" class="car-table-block__btn-empty" @click="showModal">
      {{ $t('nmhEditor.blocks.carTableBlock.empty') }}
    </button>
    <div v-else-if="carTableHasId" class="car-table-block__not-empty-wrapper">
      <span class="car-table-block__btn-icon mce-ico mce-i-custom fa fa-car"/>
      <div class="car-table-block__text">
        {{ $t('nmhEditor.blocks.carTableBlock.notEmpty') }}
        <span v-for="(item, index) in properties.testIds" :key="item">
          {{ item }}<span v-if="index !== properties.testIds.length - 1">, </span>
        </span>
      </div>
    </div>
    <ArticleTestModal
      v-if="theModalIsVisible"
      :multiple="true"
      :maxSelected="3"
      :selectedData="selectedData"
      :discriminators="articleTestDiscriminatorsCarEmbed"
      @close="hideModal"
      @set-data="setData"
    />
  </Block>
</template>

<script>
import ArticleTestModal from '@/components/article/ArticleTestModal'
import ArticleTestDiscriminatorsMixin from '@/components/mixins/valueObject/ArticleTestDiscriminatorsMixin'
import Block from '@/components/nmhEditor/blocks/Block'
export default {
  name: 'CarTableBlock',
  mixins: [ArticleTestDiscriminatorsMixin],
  components: {
    ArticleTestModal,
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      theModalIsVisible: false,
      selectedData: {
        ids: []
      }
    }
  },
  computed: {
    carTableHasId () {
      return this.properties.testIds.length > 0
    }
  },
  methods: {
    setData (data) {
      const ids = []
      data.forEach(item => ids.push(item.id))
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'testIds',
        data: ids
      })
    },
    showModal () {
      this.theModalIsVisible = true
    },
    hideModal () {
      this.theModalIsVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
  .car-table-block {
    &__not-empty-wrapper {
      @include grid-gap(9px);
      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
    }
    &__btn-empty {
      @include font(500 13px "Roboto");
      @include margin(_ auto);
      @include padding(8px 14px 8px);
      @include radius(6px);
      cursor: pointer;
      display: block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__btn-icon {
      color: #6599fe;
    }
    &__text {
      @include font(400 18px "Roboto");
      @include margin(0);
      color: #465674;
    }
  }
</style>
